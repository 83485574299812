import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Query, compose, graphql } from 'react-apollo';
import { isEqual } from 'lodash';
import { format } from 'date-fns';

import { livePrice } from '../Price/PriceCampsiteQuery';

// Utils
import getCampsiteType from '../../lib/campsiteTypes';
import updateRouterQuery, { parseQuery } from '../../lib/updateRouterQuery';
import { checkOpen } from '../../lib/helpers/availability';

import InfoWindowLoading from './InfoWindowLoading';
import InfoWindowPrices from './InfoWindowPrices';
import { LoadingSpinner } from '../ui/Loading';

import withRef from '../../hocs/withRef';
import { dictionaryItem, getDictionaryItem } from '../../hocs/withDictionary';
import { DATE_FORMAT_DISPLAY } from '../../config/locale';

// Get queries
import GET_CAMPSITE_PRICE from '../../config/graphql/getCampsitePrice';
import GET_PIN, { getPinOptions } from './graphql/getPin';
import GET_POI, { getPoiOptions } from './graphql/getPoi';

import { navigateTo, POST_TYPES } from '../../lib/helpers/navigation';

// Styles
import StyledInfoWindow, {
  StyledErrorMessage,
  CampsiteHeader,
  CampsiteImg,
  CampsiteTitle,
  CampsiteContent,
  Category,
  Footer,
  FromToDate,
  Image,
  LowAvailability,
  MoreDetails,
  OfferNote,
  OfferTitle,
  ReviewButton,
  Img,
} from './InfoWindow.style';
import { Price, ToursPrice } from './InfoWindowPrices.style';

import svgOffer from '../../static/images/icons/Offer.svg';
import Rating from '../Rating';
import IbePropTypes from '../../IbePropTypes';
import { ids } from '../../config/campsiteTypes';
import routes from '../../constants/routes';
import formatPrice from '../../lib/formatPrice';
import { SINGLE_EVENT_TYPE } from '../../config/eventTypes';
import { CAMPSITE_SEARCH_TRIGGER_IN_SESSION } from '../../lib/constants';
import { campsiteImageMapInfoKeys, mapAlternativeImages } from '../Picture/pictureHelper';
import Picture from '../Picture';
import GET_SITES_CONFIG from '../../config/graphql/getSitesConfig';

class InfoWindow extends Component {
  static propTypes = {
    campsiteId: PropTypes.string.isRequired,
    campsiteName: PropTypes.string,
    data: PropTypes.shape({
      error: PropTypes.shape({}),
      loading: PropTypes.bool,
      pinInfo: PropTypes.shape(IbePropTypes.pin),
    }),
    hasOffers: PropTypes.bool,
    isPoi: PropTypes.bool,
    lat: PropTypes.number,
    lng: PropTypes.number,
    onCampsiteSearch: PropTypes.func.isRequired,
    onQueryChange: PropTypes.func.isRequired,
    pois: PropTypes.shape({
      poi: PropTypes.arrayOf(PropTypes.shape(IbePropTypes.poi)),
    }),
    router: PropTypes.shape(IbePropTypes.router),
    type: PropTypes.number,
    zoomLevel: PropTypes.number,
    sitesConfig: PropTypes.shape(IbePropTypes.sitesConfig).isRequired,
    isSitesConfigLoading: PropTypes.bool.isRequired,
    memberPrice: PropTypes.number,
    standardPrice: PropTypes.number,
    selectedEvent: PropTypes.shape(IbePropTypes.event),
    client: PropTypes.shape(IbePropTypes.client),
    tour: PropTypes.shape(IbePropTypes.tour),
    isTours: PropTypes.bool,
  };

  static defaultProps = {
    data: {
      error: null,
      loading: false,
      pinInfo: {
        events: [],
        id: '',
        isoPlaceCode: '',
        name: '',
        numberOfReviews: 0,
        rating: 0,
        type: 0,
      },
    },
    campsiteName: '',
    hasOffers: false,
    isPoi: false,
    lat: null,
    lng: null,
    pois: {
      poi: [],
    },
    router: {
      query: {
        campsiteId: '',
        searchBy: '',
        start: '',
        end: '',
      },
    },
    type: null,
    zoomLevel: null,
    memberPrice: 0,
    standardPrice: 0,
    selectedEvent: null,
    tour: null,
    isTours: false,
    client: null,
  };

  componentDidMount() {
    const activePin = this.props.campsiteId;

    const currentCampsite = {
      id: activePin,
      name: this.props.campsiteName,
      lat: this.props.lat,
      lng: this.props.lng,
      zoomLevel: this.props.zoomLevel,
    };

    // Trigger used set for the below use in SearchWrapper, more documentation is there.
    this.props.onCampsiteSearch(
      currentCampsite,
      window.sessionStorage.getItem(CAMPSITE_SEARCH_TRIGGER_IN_SESSION),
    );

    window.sessionStorage.removeItem(CAMPSITE_SEARCH_TRIGGER_IN_SESSION);
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.router.query.campsiteId !== this.props.router.query.campsiteId) return true;
    if (nextProps.router.query.start !== this.props.router.query.start) return true;
    if (nextProps.router.query.end !== this.props.router.query.end) return true;
    if (nextProps.campsiteId !== this.props.campsiteId) return true;
    if (nextProps.data.error !== this.props.data.error) return true;
    if (nextProps.data.loading !== this.props.data.loading) return true;
    if (!isEqual(nextProps.data.pinInfo, this.props.data.pinInfo)) return true;
    if (nextProps.memberPrice !== this.props.memberPrice) return true;
    if (nextProps?.selectedEvent?.id !== this.props.selectedEvent) return true;
    return false;
  }

  handleMoreDetailsClick = () => {
    updateRouterQuery(routes.sites, { campsiteId: this.props.campsiteId });
  }

  handleReviewsClick = () => {
    this.props.onQueryChange({ siteCode: null });
    updateRouterQuery(routes.sites, {
      siteCode: null,
      reviews: true,
      campsiteId:
        this.props.campsiteId,
    });
  };

  renderImage = (galleryItem, alt) => {
    if (!galleryItem) {
      return <></>;
    }

    const galleryImage = mapAlternativeImages(
      galleryItem.url,
      galleryItem.alternativeFormats,
      campsiteImageMapInfoKeys,
    );

    if (!galleryImage) {
      // maintain compatibility with components that don't utilize new optimized image structure
      return <Img
        alt={alt}
        src={galleryItem || ''}
        loading="eager"
      />;
    }

    return (
      <Picture image={galleryImage}>
        <Img
          alt={alt}
          src={galleryImage.baseUrl || ''}
          loading="eager"
        />
      </Picture>
    );
  }

  render() {
    if (this.props.data.loading || this.props.isSitesConfigLoading) {
      return (
        <StyledInfoWindow>
          <InfoWindowLoading />
        </StyledInfoWindow>
      );
    }

    if (
      !this.props.isTours &&
      this.props.data.error
      && (!this.props.data.pinInfo || !this.props.data.pinInfo.id)
    ) {
      return (
        <StyledInfoWindow>
          <StyledErrorMessage />
        </StyledInfoWindow>
      );
    }

    const query = parseQuery(this.props.router.query);

    const {
      campsiteId, selectedEvent, isTours, tour, client,
    } = this.props;
    const {
      start, end, eventType, bookingId, componentId,
    } = query;

    if (
      (this.props.data.error && !this.props.isTours)
      || (!this.props.isPoi && this.props.data &&
        !this.props.data.pinInfo && !this.props.isTours)
    ) return null;

    const campsiteType = getCampsiteType(
      this.props.sitesConfig.campsiteTypes,
      this.props.type,
    );

    const poiData = this.props.pois?.poi?.find(
      pointOfInterest => pointOfInterest.id === this.props.campsiteId,
    );

    const isPoi = this.props.isPoi || poiData;

    // pinInfo is only available on a campsite and not a POI
    const campsiteData = this.props.data?.pinInfo || tour;

    const id = isPoi ? poiData?.id : campsiteData?.id;
    const numberOfReviews = isPoi ? null : campsiteData?.numberOfReviews;
    const poiImageGallery = poiData?.image ? [poiData?.image] : [];
    const gallery = isPoi ? poiImageGallery : campsiteData?.gallery;
    const rating = isPoi ? null : campsiteData?.rating;
    const name = isPoi ? poiData.name : campsiteData?.name;
    const events = campsiteData && campsiteData.events;
    const openDates =
      selectedEvent?.openDates || events?.[0]?.openDates;

    const noPriceCampsiteTypes = [
      ids.UK_AFFILIATED_SITES,
      ids.CERTIFICATED_LOCATIONS,
      ids.OVERSEAS_TOUR,
    ];

    const noRatingCampsiteTypes = [
      ids.UK_AFFILIATED_SITES,
      ids.OVERSEAS_TOUR,
    ];

    const showPrice = noPriceCampsiteTypes.includes(this.props.type) === false;
    const showRating = noRatingCampsiteTypes.includes(this.props.type) === false;
    const eventImage = gallery?.[eventType] || Object.values(gallery ?? {})?.[0];

    return (
      <StyledInfoWindow
        color={campsiteType.color}
        key={id}
        id="InfoWindow" // Used to prevent map clicks closing the InfoWindow (Campsitemap.jsx handleMapClick)
      >
        <CampsiteHeader>
          {((isPoi || isTours) && gallery?.length > 0) &&
            <CampsiteImg>
              <Image>
                {this.renderImage(gallery[0], name)}
              </Image>
            </CampsiteImg>
          }
          {!isPoi && !isTours &&
            <CampsiteImg>
              {eventImage && (
                <Image>
                  {this.renderImage(eventImage, name)}
                </Image>
              )}
            </CampsiteImg>
          }
          {
            isTours && (
              <Category backgroundColor={campsiteType?.color}>
                {campsiteType?.text}
              </Category>
            )
          }

          <CampsiteTitle>
            {name}<br />
            {isTours &&
              <>
                {tour?.numberOfNights} {getDictionaryItem(client, 'InfoWindow__ToursNightsSuffix__Text')}
              </>}
          </CampsiteTitle>
          {(numberOfReviews !== null && showRating) &&
            <ReviewButton
              disabled={!numberOfReviews}
              onClick={this.handleReviewsClick}
            >
              <Rating
                noOfReviews={numberOfReviews}
                rating={rating}
                size="0.625rem"
              />
            </ReviewButton>
          }
        </CampsiteHeader>

        {isTours && (
          <ToursPrice>
            <span>{getDictionaryItem(client, 'InfoWindow__ToursPricePrefix__Text')}</span>
            <Price inline>{formatPrice(tour?.fromPrice, undefined, isTours)}</Price>
            <span>{tour?.fromPriceSuffix?.toLowerCase()}</span>
          </ToursPrice>
        )}

        {isPoi && (
          <>
            <FromToDate>
              {format(new Date(poiData.startDateTime), DATE_FORMAT_DISPLAY)}
              {' - '}
              {format(new Date(poiData.endDateTime), DATE_FORMAT_DISPLAY)}
            </FromToDate>
            <CampsiteContent>
              {poiData.promotionalText}
            </CampsiteContent>
            <Footer>
              {poiData.offerTitle && (
                <>
                  <OfferNote
                    align="center"
                    icon={svgOffer}
                    dictionary={dictionaryItem('InfoWindow', 'OffersAvailable')}
                  />
                  <OfferTitle
                    align="center"
                    text={poiData.offerTitle}
                    marginTop
                    bold
                  />

                  <MoreDetails
                    size="small"
                    hasOffers
                    onClick={() => navigateTo(poiData.poIUrl, {
                      type: POST_TYPES.NEW_WINDOW,
                    })}
                    dictionary={dictionaryItem('InfoWindow', 'ClaimOffer')}
                  />
                </>
              )}
            </Footer>
          </>
        )}
        {!isPoi && isTours &&
          <Footer>
            <MoreDetails
              marginTop={!showPrice}
              size="small"
              hasOffers={false}
              onClick={this.handleMoreDetailsClick}
              disabled={this.props.campsiteId === this.props.router.query.campsiteId}
              siteDetailsVisible={
                this.props.campsiteId === this.props.router.query.campsiteId
              }
              dictionary={dictionaryItem('InfoWindow', 'MoreDetails')}
            />
          </Footer>
        }
        {(!isPoi && !isTours &&
          <Query
            query={GET_CAMPSITE_PRICE}
            variables={{
              campsiteId,
              pitchId: (bookingId && componentId) ? componentId : undefined,
              ...livePrice(start, end),
            }}
          >
            {(priceResponse) => {
              if (!priceResponse.data && !this.props.memberPrice) return null;
              const campsitePriceData = priceResponse?.data?.campsitePrice?.data?.[0] ??
                null;
              const allEventsAreMemberExclusive = campsitePriceData?.events?.every(
                e => e.isMemberExclusive,
              );
              const isOverseasSite = this.props.type === ids.OVERSEAS_SITE;
              // Find the matched event, if none, select the first
              const eventPrice = campsitePriceData?.events?.find(
                (event) => event.eventType === Number(eventType) || (
                  isOverseasSite && event.eventType === SINGLE_EVENT_TYPE.id
                ),
              ) ?? campsitePriceData?.events?.[0] ?? selectedEvent ?? { offers: [] };

              const hasOffers = this.props.hasOffers || !!eventPrice.offers?.length;
              const memberPrice = this.props.memberPrice || eventPrice.cheapestMemberPrice;
              let standardPrice =
                this.props.standardPrice || eventPrice.cheapestStandardPrice || memberPrice;

              if (
                allEventsAreMemberExclusive
                || (selectedEvent && selectedEvent.isMemberExclusive)
              ) {
                standardPrice = null;
              }

              const hasLowAvailability = eventPrice && eventPrice.hasLowAvailability;

              return (
                <>
                  {showPrice &&
                    <CampsiteContent>
                      <InfoWindowPrices
                        parentLoading={this.props.data.loading}
                        isOpen={checkOpen(
                          eventPrice?.openDates || openDates,
                          query.start,
                          query.end,
                        )}
                        memberPrice={memberPrice}
                        standardPrice={isOverseasSite ? null : standardPrice}
                        isTours={isTours}
                      />
                    </CampsiteContent>
                  }
                  {priceResponse.loading && <LoadingSpinner size="1rem" />}

                  <Footer>
                    {hasLowAvailability &&
                      <LowAvailability dictionary={dictionaryItem('InfoWindow', 'LowAvailability')} />
                    }

                    {hasOffers && (
                      <OfferNote
                        align="center"
                        icon={svgOffer}
                        dictionary={dictionaryItem('InfoWindow', 'OffersAvailable')}
                      />
                    )}

                    <MoreDetails
                      marginTop={!showPrice}
                      size="small"
                      hasOffers={hasOffers}
                      onClick={this.handleMoreDetailsClick}
                      disabled={this.props.campsiteId === this.props.router.query.campsiteId}
                      siteDetailsVisible={
                        this.props.campsiteId === this.props.router.query.campsiteId
                      }
                      dictionary={dictionaryItem('InfoWindow', 'MoreDetails')}
                    />
                  </Footer>
                </>
              );
            }}
          </Query>
        )}
      </StyledInfoWindow>
    );
  }
}

export default compose(
  withRef,
  graphql(GET_SITES_CONFIG, {
    props: ({ data }) => ({
      sitesConfig: data.configurationSites,
      isSitesConfigLoading: data.loading,
    }),
  }),
  graphql(GET_PIN, { options: getPinOptions }), // campsite pin API call, if poi this is skipped
  graphql(GET_POI, { options: getPoiOptions, name: 'pois' }), // cached poi data, if campsite this is skipped
)(InfoWindow);
