import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { graphql, compose } from 'react-apollo';
import { withRouter } from 'next/router';

// Utils
import buildPath from '../../lib/helpers/restLink';
import queryHelper from '../../lib/queryHelper';

import ListingItem from '../Listing/ListingItem';
import { LoadingSpinner } from '../ui/Loading';

// Styles
import StyledFixedInfoWindow from './FixedInfoWindow.style';

// Get queries
import GET_CAMPSITE_PRICE, { campsitePriceOptions } from '../../config/graphql/getCampsitePrice';
import GET_CAMPSITE_LISTING from '../Listing/graphql/getCampsiteListing';
import { UPDATE_CURRENT_CAMPSITE } from '../../config/graphql';
import GET_CAMPSITE, {
  getCampsiteOptions,
} from '../../config/graphql/getCampsite';
import IbePropTypes from '../../IbePropTypes';
import { mergeListingAndPrices, shouldSkipCampsiteListing } from '../Search/CampsiteListAndPriceQueryHelpers';
import PLACE_EVENT_TYPES from '../../config/eventTypes';
import GET_POI, { getPoiOptions } from './graphql/getPoi';
import GET_PIN, { getPinOptions } from './graphql/getPin';
import { addTiming, types } from '../../lib/timings';
import { checkOpen } from '../../lib/helpers/availability';
import { getCampsiteFeatureByTag } from '../../lib/helpers/campsites';
import FEATURE_TYPES from '../../config/featureTypes';
import GET_SITES_CONFIG from '../../config/graphql/getSitesConfig';

/**
 * We are handling data for the rendering of Tour list items and Campsite List items,
 * hence the checking for different id types.
 * this allows us to mirror the data requests and re use the Listing component,
 * to be honest this should be refactored.
 */
class FixedInfoWindow extends PureComponent {
  static propTypes = {
    campsite: PropTypes.shape(IbePropTypes.campsite).isRequired,
    campsiteListing: PropTypes.shape({
      data: PropTypes.arrayOf(PropTypes.shape(IbePropTypes.campsiteListingItem)),
    }),
    campsiteName: PropTypes.string,
    error: PropTypes.shape({}),
    eventType: PropTypes.number,
    isOverseas: PropTypes.bool,
    tour: PropTypes.shape(IbePropTypes.tour),
    isTours: PropTypes.bool.isRequired,
    lat: PropTypes.string,
    lng: PropTypes.string,
    campsiteId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    sitesConfig: PropTypes.shape(IbePropTypes.sitesConfig).isRequired,
    handleClick: PropTypes.func.isRequired,
    updateCurrentCampsite: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    loadingPrices: PropTypes.bool,
    pricesData: PropTypes.shape({
      networkStatus: PropTypes.number,
      campsitePrice: PropTypes.oneOfType([PropTypes.number, PropTypes.shape({
        data: PropTypes.arrayOf(PropTypes.shape(IbePropTypes.price)),
      })]),
    }),
    query: PropTypes.shape(IbePropTypes.query).isRequired,
    zoomLevel: PropTypes.number,
    zoomTo: PropTypes.number,
    isPoi: PropTypes.bool,
    pois: PropTypes.shape({
      poi: PropTypes.arrayOf(PropTypes.shape(IbePropTypes.poi)),
    }),
  };

  static defaultProps = {
    campsiteName: '',
    error: null,
    eventType: PLACE_EVENT_TYPES.TOURING.id,
    isOverseas: false,
    lat: '',
    lng: '',
    campsiteListing: undefined,
    pricesData: {
      networkStatus: 0,
      campsitePrice: 0,
    },
    loading: false,
    loadingPrices: false,
    tour: undefined,
    zoomLevel: 0,
    zoomTo: 0,
    isPoi: false,
    pois: {
      poi: [],
    },
  };

  componentDidMount() {
    this.props.updateCurrentCampsite({
      id: String(this.props.campsiteId),
      name: this.props.campsiteName,
      lat: this.props.lat,
      lng: this.props.lng,
      zoomLevel: this.props.zoomLevel,
      zoomTo: this.props.zoomTo,
    });
  }

  componentDidUpdate(prevProps) {
    // TEMP Performance Hooks, TODO can be safely removed at any time
    // conditional to check actual setting of campsite, not just rerender
    if (prevProps?.campsiteId !== this.props?.campsiteId) {
      addTiming(types.MAP_CAMPSITE_MARKER, 'Campsite Card Loaded');
    }
  }

  onLoadImage = () => {
    // TEMP Performance Hooks, TODO can be safely removed at any time
    addTiming(types.MAP_CAMPSITE_MARKER, 'Campsite Card Image Complete', true);
  }

  render() {
    if (this.props.loading ||
      this.props.pricesData.networkStatus === 1
    ) {
      return (
        <StyledFixedInfoWindow>
          <LoadingSpinner />
        </StyledFixedInfoWindow>
      );
    }

    if (!this.props.campsiteId || this.props.error) {
      return (
        <StyledFixedInfoWindow>
          Error
        </StyledFixedInfoWindow>
      );
    }

    // if we are a regular campsite and not a tour we utilize the same
    // data that is used in the Listing called in CampsiteListAndPriceQuery
    const campsitePrice = !!this.props.pricesData.campsitePrice && !this.props.isTours ?
      this.props.pricesData.campsitePrice.data[0] || {} :
      {};

    let campsite;

    if (this.props.campsite) {
      // mimic merge of data in CampsiteListAndPriceQuery
      campsite = mergeListingAndPrices(
        [this.props.campsite],
        [...this.props.pricesData?.campsitePrice?.data || []],
        this.props.eventType,
        this.props.isOverseas,
      ).pop();

      // format campsite for display in ListingResult
      campsite.gallery = campsite?.events?.length > 0 ? getCampsiteFeatureByTag(
        campsite.events[0].features,
        FEATURE_TYPES.GALLERY,
        false,
      ).map((f) => ({
        url: f.value,
        alternativeFormats: f.alternativeImages,
      })) : [];

      // limited to 7 to avoid clipping
      campsite.topFeatures = getCampsiteFeatureByTag(
        campsite.features,
        FEATURE_TYPES.TOP_TEN,
      ).slice(0, 7);
    }

    const selectedEvent = campsite?.placeEvent?.find(
      ({ eventType }) => eventType === this.props.eventType,
    );

    return (
      <StyledFixedInfoWindow>

        {this.props.isTours ? (
          <>
            {this.props.tour &&
              <ListingItem
                {...this.props.tour}
                loadingCampsites={false}
                onResultClick={this.props.handleClick}
                activePin={this.props.query.activePin}
                tour={this.props.tour}
                isTours
                showDates={!!this.props.query.departureMonth}
                loadingPrices={false}
                isFeatured={false}
                departureMonth={this.props.query.departureMonth}
              />
            }
          </>
        ) : (
          <>
            {campsite && (
              <ListingItem
                campsiteTypes={this.props.sitesConfig.campsiteTypes}
                {...campsitePrice}
                {...campsite}
                hasOffers={(campsite.offers && campsite.offers.length)}
                isOpen={checkOpen(
                  selectedEvent?.openDates, this.props.query?.start, this.props.query?.end,
                )}
                isTours={this.props.isTours}
                loading={this.props.loading}
                loadingCampsites={false}
                loadingPrices={this.props.loadingPrices}
                onLoadImage={this.onLoadImage}
                onResultClick={this.props.handleClick}
                placeEvent={campsite.events}
                showEvents={false}
              />
            )}
          </>
        )}
      </StyledFixedInfoWindow>
    );
  }
}

export default compose(
  withRouter,
  graphql(GET_SITES_CONFIG, {
    props: ({ data }) => ({ sitesConfig: data.configurationSites }),
  }),
  queryHelper(GET_CAMPSITE_LISTING, (props) => {
    const listingVariables = {
      ...props.searchVariables,
      page: 0,
      pageSize: 20,
      skip: shouldSkipCampsiteListing(props) || typeof props.activePin === 'number',
    };

    return {
      notifyOnNetworkStatusChange: true,
      name: 'listingData',
      variables: {
        pathBuilder: buildPath('campsite/search'),
        ...listingVariables,
        skip: props.isTours,
      },
    };
  }),
  graphql(GET_CAMPSITE_PRICE, {
    name: 'pricesData',
    props: (data) => ({
      loadingPrices: data.loading,
      ...data,
    }),
    options: campsitePriceOptions,
    skip: ({ isTours, campsiteId }) => {
      // skip as may be set to a tour id
      const isNotGenuineCampsiteId = !Number.isNaN(Number(campsiteId)) && campsiteId !== undefined;
      return !!isTours || isNotGenuineCampsiteId;
    },
  }),
  graphql(UPDATE_CURRENT_CAMPSITE, {
    props: ({ mutate }) => ({
      updateCurrentCampsite: campsiteInfo => mutate({ variables: campsiteInfo }),
    }),
  }),
  graphql(GET_PIN, { options: getPinOptions }),
  graphql(GET_POI, { options: getPoiOptions, name: 'pois' }), // cached poi data, if campsite this is skipped
  queryHelper(GET_CAMPSITE, getCampsiteOptions),
)(FixedInfoWindow);
