import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose, graphql, withApollo } from 'react-apollo';
import { withTheme } from 'styled-components';
import { debounce, uniqWith, isEqual } from 'lodash';

import alwaysArray from '../../lib/alwaysArray';
import testingAttr from '../../lib/testingAttr';
import { parseQuery } from '../../lib/updateRouterQuery';
import { standardPropTypes } from '../../lib/standardProptypes';
import FETCH_POLICY from '../../constants/FetchPolicy';
import GET_POPUP from '../PopUp/graphql/getPopUp';

import FiltersList from './FiltersList';
import ItineraryList from './SuggestedUkItinerary/ItineraryList';
import { LoadingSpinner } from '../ui/Loading';

import FiltersStyled, {
  Body,
  ButtonClear,
  ButtonSubmit,
  Center,
  Close,
  Col,
  ButtonContainer,
  Container,
  Footer,
  Header,
  Message,
} from './Filters.style';

import { Row } from '../ui/Grid';

import GET_FILTERS, { getAggregationsVariables } from './graphql/getFilters';
import { parseTypes } from '../../lib/campsiteTypes';
import IbePropTypes from '../../IbePropTypes';
import { nonFilterableTypes, ids as campsiteTypeIds } from '../../config/campsiteTypes';
import { FEATURE_FLAGS, getFeatureFlag } from '../../config/featureFlags';
import zIndex from '../../config/zIndex';
import withSitesConfig from '../../hocs/withSitesConfig';

function getFilters({
  features = [],
  campsiteTypes = [],
  nearbyFilterableFeatures = [],
  onSiteFilterableFeatures = [],
  disabledFilterableFeatures = [],
  pointsOfInterestFilterableFeatures = [],
}) {
  return {
    campsiteTypes:
      campsiteTypes.filter(campsiteType => !nonFilterableTypes.includes(campsiteType.key)),
    nearbyFilterableFeatures:
      uniqWith(features.filter(
        feature => nearbyFilterableFeatures.some(key => feature.id === key),
      ), isEqual),
    onSiteFilterableFeatures:
      uniqWith(features.filter(
        feature => onSiteFilterableFeatures.some(key => feature.id === key),
      ), isEqual),
    disabledFilterableFeatures:
      uniqWith(features.filter(
        feature => disabledFilterableFeatures.some(key => feature.id === key),
      ), isEqual),
    pointsOfInterestFilterableFeatures:
      uniqWith(features.filter(
        feature => pointsOfInterestFilterableFeatures
          .some(key => feature.id === key),
      ), isEqual),
  };
}

class Filters extends Component {
  static propTypes = {
    active: PropTypes.bool,
    client: PropTypes.shape(IbePropTypes.client).isRequired,
    filterData: PropTypes.shape({
      error: PropTypes.shape({}),
      fetchMore: PropTypes.func,
      loading: PropTypes.bool,
      results: PropTypes.shape({
        aggregations: PropTypes.arrayOf(PropTypes.shape({
          key: PropTypes.string,
          count: PropTypes.number,
        })),
        count: PropTypes.number,
      }),
      networkStatus: PropTypes.number,
    }),
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onViewItinerary: PropTypes.func,
    query: PropTypes.shape(IbePropTypes.query).isRequired,
    theme: PropTypes.shape({
      COLOR_WARNING: PropTypes.string,
      COLOR_WHITE: PropTypes.string,
    }),
    isPoi: PropTypes.bool,
    sitesConfig: PropTypes.shape(IbePropTypes.sitesConfig).isRequired,
    suggestedUkItineraries: PropTypes.arrayOf(PropTypes.shape(IbePropTypes.ukItinerary)),
  }

  static defaultProps = {
    active: false,
    theme: {},
    isPoi: false,
    onViewItinerary: () => { },
    suggestedUkItineraries: [],
    filterData: {},
    ...standardPropTypes,
  }

  fetchAggregations = debounce(() => {
    if (this.props.isPoi) {
      return;
    }
    const query = {
      ...this.props.query,
      ...this.state.activeFilters,
    };

    const variables = getAggregationsVariables(query);

    this.props.filterData.fetchMore({
      variables,
      updateQuery: (previous, { fetchMoreResult }) => ({ ...fetchMoreResult }),
    });
  }, 500);

  body = window.document.querySelector('body');

  constructor(props) {
    super(props);

    const query = parseQuery(props.query);

    this.state = {
      activeFilters: {
        features: alwaysArray(query.features),
        /* if you want to have it just greyed out change it array to [] */
        types: parseTypes(query.types),
        ukItinerary: query.ukItinerary,
      },
    };
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.active && this.props.active) {
      this.handleOpen();
    }
  }

  handleOpen = () => {
    // set filters to current query
    const { query } = this.props;

    const activeFilters = {
      features: alwaysArray(query.features),
      types: parseTypes(query.types),
      ukItinerary: query.ukItinerary,
    };

    // Reset filters and close modal
    this.setState({ activeFilters });
  }

  handleClose = () => {
    const { onClose, query } = this.props;

    const activeFilters = {
      features: alwaysArray(query.features),
      types: parseTypes(query.types),
      ukItinerary: query.ukItinerary,
    };

    // Reset filters and close modal
    this.setState({ activeFilters });
    onClose();
  }

  handleSubmit = () => {
    this.props.onSubmit({ ...this.state.activeFilters });
    this.props.onClose();
  }

  handleToggleFilter = (id, keypath) => {
    const { activeFilters } = this.state;
    let filters = [...activeFilters[keypath]];

    const exists = filters.indexOf(id) > -1;

    if (exists) {
      // id is a mixed type of number | string to handle campsite type(number), feature (string)
      const itemId = typeof id === 'string' ? id.toLowerCase() : id;
      filters = filters.filter(
        (filterId) => (typeof filterId === 'string' ? filterId.toLowerCase() : filterId) !== itemId,
      );
    } else {
      filters.push(id);
    }

    activeFilters[keypath] = filters;

    this.setState({ activeFilters }, this.fetchAggregations);
  }

  /**
   * Reset to defaults
   */
  handleReset = () => {
    const { query, sitesConfig } = this.props;
    const isOverseas = query?.isOverseas === 'true';
    const defaultTypesOverseas = sitesConfig?.overseasSitesTabDefaultSearchPlaceTypes;
    const defaultTypesUK = sitesConfig?.ukSitesTabDefaultSearchPlaceTypes;
    const overseasOnlyType = [campsiteTypeIds.OVERSEAS_SITE];
    let types = isOverseas ? defaultTypesOverseas : defaultTypesUK;
    if (!getFeatureFlag(FEATURE_FLAGS.UK_SITES)) {
      types = overseasOnlyType;
    }
    const activeFilters = {
      features: [],
      types,
      ukItinerary: '',
    };

    this.setState({ activeFilters }, () => {
      this.fetchAggregations();
      this.props.onSubmit({ ...activeFilters });
    });
  }

  handleSelectUkItinerary = (itineraryId) => {
    if (itineraryId === this.state.activeFilters.ukItinerary) {
      this.handleReset();
    } else {
      const activeFilters = {
        features: [],
        types: [],
        ukItinerary: itineraryId,
      };
      this.setState({ activeFilters }, this.fetchAggregations);
    }
  }

  render() {
    const {
      active, theme, client,
      isPoi, query, suggestedUkItineraries,
      filterData,
      sitesConfig,
    } = this.props;

    const { features, types, ukItinerary } = this.state.activeFilters;
    const isOverseas = query?.isOverseas === 'true';
    const campsiteFilters = getFilters(sitesConfig);

    const numberTypes = types.map(type => Number(type));

    const firstLoad = filterData?.networkStatus === 1;
    const showCount = filterData.results && filterData.results.count &&
      !isPoi && !this.state.activeFilters.ukItinerary;

    return (
      <FiltersStyled zIndex={zIndex.MODAL} active={active} fullscreen>
        <Header>
          <ButtonContainer>
            <Close
              onClick={this.handleClose}
              {...testingAttr('filers__close-btn')}
            />
          </ButtonContainer>
        </Header>
        <Body>
          <Center>
            <Container>
              {firstLoad &&
                <LoadingSpinner />
              }

              {!firstLoad &&
                <FiltersList
                  isPoi={isPoi}
                  aggregations={
                    filterData.results ? filterData.results.aggregations : []
                  }
                  activeFeatures={features}
                  activeTypes={numberTypes}
                  campsiteFilters={campsiteFilters}
                  handleToggleFilter={this.handleToggleFilter}
                  isOverseas={isOverseas}
                  data-testid="filtersList"
                  disabled={!!ukItinerary}
                />
              }
              {!isOverseas && !isPoi && (<ItineraryList
                itineraries={suggestedUkItineraries}
                activeId={ukItinerary}
                handleSelectUkItinerary={this.handleSelectUkItinerary}
                handleViewItinerary={this.props.onViewItinerary}
                categories={sitesConfig?.suggestedUkItineraryCategories}
                data-testid="suggestedUkItineraryList"
                client={client}
              />)}
            </Container>
          </Center>
        </Body>
        <Footer>
          {filterData.results && !filterData.results.count && !isPoi && !ukItinerary &&
            <Message
              backgroundColor={theme.COLOR_WARNING}
              color={theme.COLOR_WHITE}
            >
              There are no results matching the chosen filters.
            </Message>
          }
          <Row>
            <Col flexDirection="row">
              <ButtonClear
                block
                onClick={this.handleReset}
                disabled={this.state.activeFilters.length === 0}
                {...testingAttr('filters__clear-btn')}
              >
                Clear All
              </ButtonClear>
            </Col>
            <Col>
              <ButtonSubmit
                block
                disabled={
                  filterData.loading
                  || (filterData.results && !filterData.results.count && !isPoi && !ukItinerary)
                }
                onClick={this.handleSubmit}
                {...testingAttr('filters__update-btn')}
              >
                <span>Update</span>
                {showCount &&
                  <span>&nbsp;({filterData.results.count})</span>
                }
              </ButtonSubmit>
            </Col>
          </Row>
        </Footer>
      </FiltersStyled>
    );
  }
}

export default compose(
  withTheme,
  withApollo,
  graphql(GET_FILTERS, {
    options: ({ query }) => ({
      notifyOnNetworkStatusChange: true,
      variables: getAggregationsVariables(query),
      fetchPolicy: FETCH_POLICY.NETWORK_ONLY,
    }),
    skip: ({ active }) => !active,
    props: ({ data }) => ({
      filterData: data,
    }),
  }),
  withSitesConfig,
  graphql(GET_POPUP, {
    name: 'popups',
  }),
)(Filters);
