import React, {
  memo,
  useState,
  useEffect,
  useRef,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { graphql, compose, withApollo } from 'react-apollo';
import { useQuery } from '@apollo/client';

import CampsiteListAndPriceQuery from './CampsiteListAndPriceQuery';
import CampsiteSearchSummary from './CampsiteSearchSummary';
import CampsiteTours from './CampsiteTours';
import SearchResults from '../SearchResults';
import { SelectWrapper, Select } from '../SearchResults/SearchResultsHeader.style';

import { Body } from './CampsiteSearch.style';

import { getDictionaryItem, mapDictionaryItems } from '../../hocs/withDictionary/utils';
import { TOURS_SORT_TYPES, sortTours, searchTours } from '../../lib/helpers/tours';
import {
  CAMPSITE_SORT_TYPES, CAMPSITE_SORT_LABELS,
} from '../../lib/campsiteSearch';
import IbePropTypes from '../../IbePropTypes';
import { searchByType } from '../../constants/search';
import GET_CAMPSITE_SORT_TYPE from '../../config/graphql/getCampsiteSortType';
import { getLocationCoordinatesInStorage } from './CampsiteListAndPriceQueryHelpers';
import { addTiming, types } from '../../lib/timings';
import { addOrUpdateQueryParam } from '../../lib/updateRouterQuery';
import GET_SITES_CONFIG from '../../config/graphql/getSitesConfig';

const propTypes = {
  data: PropTypes.shape({
    campsiteSortType: PropTypes.number,
  }),
  error: PropTypes.shape({}),
  goToTop: PropTypes.func.isRequired,
  handleChangeSearch: PropTypes.func.isRequired,
  handleFiltersClick: PropTypes.func.isRequired,
  handleClearUkItinerary: PropTypes.func,
  handleResultClick: PropTypes.func,
  renderSearchForm: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  isMobileMapOpen: PropTypes.bool.isRequired,
  networkStatus: PropTypes.number,
  onAlternativeSearchSubmit: PropTypes.func,
  onQueryChange: PropTypes.func.isRequired,
  onResultClick: PropTypes.func.isRequired,
  onResultsUpdate: PropTypes.func.isRequired,
  handleNearby: PropTypes.func.isRequired,
  query: PropTypes.shape(IbePropTypes.query),
  refMap: PropTypes.shape({}),
  results: PropTypes.arrayOf(PropTypes.shape({})),
  searchFormProps: PropTypes.shape({}),
  showResults: PropTypes.bool.isRequired,
  toggleBasket: PropTypes.func.isRequired,
  variables: PropTypes.shape({}).isRequired,
  client: PropTypes.shape(IbePropTypes.client).isRequired,
  onProductTypeMismatch: PropTypes.func,
  renderEventsToggle: PropTypes.func,
  renderToursToggle: PropTypes.func,
  suggestedUkItineraries: PropTypes.arrayOf(PropTypes.shape(IbePropTypes.ukItinerary)),
  tours: PropTypes.arrayOf(PropTypes.shape(IbePropTypes.tour)),
  isTours: PropTypes.bool,
  searchBy: PropTypes.string,
  coords: PropTypes.shape({}),
  sitesConfig: PropTypes.shape(IbePropTypes.sitesConfig).isRequired,
  mapMovedCount: PropTypes.number,
};

const defaultProps = {
  data: undefined,
  error: null,
  results: [],
  query: {
    features: '',
    types: '',
  },
  refMap: null,
  networkStatus: null,
  handleResultClick: () => { },
  handleClearUkItinerary: () => { },
  onAlternativeSearchSubmit: () => { },
  renderEventsToggle: () => { },
  renderToursToggle: () => { },
  searchFormProps: null,
  onProductTypeMismatch: null,
  suggestedUkItineraries: [],
  tours: [],
  isTours: false,
  searchBy: searchByType.LOCATION,
  coords: null,
  mapMovedCount: 0,
};

function CampsiteResults({
  error,
  handleChangeSearch,
  handleNearby,
  handleFiltersClick,
  handleClearUkItinerary,
  isMobile,
  isMobileMapOpen,
  onQueryChange,
  onResultClick,
  onResultsUpdate,
  query,
  refMap,
  renderSearchForm,
  showResults,
  toggleBasket,
  variables,
  client,
  onProductTypeMismatch,
  renderEventsToggle,
  renderToursToggle,
  suggestedUkItineraries,
  tours,
  isTours,
  searchBy,
  sitesConfig,
  mapMovedCount,
}) {
  const [count, setCount] = useState(null);
  const [total, setTotal] = useState(null);
  const [viewMoreLoading, setViewMoreLoading] = useState(false);

  const resultsContainerRef = useRef(null);

  const [result, setResult] = useState(tours);
  const { departureMonth, duration, location } = query ?? {};
  const activeUkItinerary = suggestedUkItineraries.find(
    (item) => item.id?.toString() === query.ukItinerary,
  );
  const [page, setPage] = useState(0);

  const {
    overseasSitesTabDefaultSearchSortOrderTypes,
    ukSitesTabDefaultSearchSortOrderTypes,
  } = sitesConfig;
  const searchSortOrder = query.isOverseas
    ? overseasSitesTabDefaultSearchSortOrderTypes
    : ukSitesTabDefaultSearchSortOrderTypes;

  /**
   * As mentioned below the resetting of the page is crucial when change occurs in query params
   * that affect the API calls that change what is displayed in the listing,
   * in CampsiteListAndPriceQuery
   * @param {number} value sortType
   */
  const setSortType = (value) => {
    addOrUpdateQueryParam('sortType', value);
    setPage(0); // reset page
    client.writeData({
      data: {
        campsiteSortType: value,
      },
    });
  };

  const setResultsScrollRef = (ref) => {
    resultsContainerRef.current = ref;
  };

  // campsiteSortType pulled from client Apollo store, not retrieved remotely
  const { data: { campsiteSortType: sortType } } = useQuery(GET_CAMPSITE_SORT_TYPE, { client });

  useEffect(() => {
    if (tours?.length) {
      const newResult = searchTours({
        tours,
        departureMonth,
        duration,
        location,
        bounds: query,
      });
      const sortedTours = newResult?.length ? sortTours(newResult, sortType) : [];
      setResult(sortedTours);
      setCount(sortedTours.length);
    }
  }, [tours, departureMonth, duration, location, query.ne_lat, query.sw_lon, sortType]);

  /**
   * When query params are changed we reset the page state here, this is tied to logic in
   * CampsiteListAndPriceQuery around caching paginated pages, be mindful of the query
   * params that affect the paginated cache.
   */
  useEffect(() => {
    setPage(0); // reset page
  }, [
    query.ne_lat,
    query.ne_lng,
    query.sw_lon,
    query.sw_lng,
    query?.types?.toString(),
    query.features?.toString(),
  ]);

  const sortOptions = mapDictionaryItems(client, CAMPSITE_SORT_LABELS);
  const { activePin, isOverseas, eventType } = query;

  const nextPage = useCallback(() => {
    setPage((prevPage) => {
      const newPage = prevPage + 1;
      return newPage;
    });
    setViewMoreLoading(true);
  }, []);

  // TEMP Performance Hooks, TODO can be safely removed at any time
  useEffect(() => {
    if (location) addTiming(types.SEARCH_LOCATION, 'Results UI Ready');
  }, []);

  const correctSortTypes = Object.values(isTours ? TOURS_SORT_TYPES : CAMPSITE_SORT_TYPES);
  const applicableSortTypeOptions = correctSortTypes.filter((sortTypeOption) => {
    const allowDistance =
      sortTypeOption === TOURS_SORT_TYPES.DISTANCE_ASC
      && searchBy !== searchByType.CAMPSITE
      && getLocationCoordinatesInStorage();
    const isNotDistance = sortTypeOption !== TOURS_SORT_TYPES.DISTANCE_ASC;
    const isAllowedForThisProduct = searchSortOrder?.includes(sortTypeOption) ?? true;
    return isAllowedForThisProduct && (allowDistance || isNotDistance);
  });

  if (searchSortOrder?.length) {
    applicableSortTypeOptions.sort(
      (a, b) => searchSortOrder.indexOf(a) - searchSortOrder.indexOf(b),
    );
  }

  const sortByText = getDictionaryItem(client, 'SearchSort__SortBy__Text');

  return (
    <Body>
      <SearchResults
        renderEventsToggle={renderEventsToggle}
        renderToursToggle={renderToursToggle}
        handleClearUkItinerary={handleClearUkItinerary}
        isCrossings={false}
        count={count}
        total={total}
        error={error}
        isMobileMapOpen={isMobileMapOpen}
        refMap={refMap}
        activeUkItinerary={activeUkItinerary}
        nextPage={nextPage}
        client={client}
        viewMoreLoading={viewMoreLoading}
        isOverseas={isOverseas === 'true'}
        setResultsListRef={setResultsScrollRef}
        loading={viewMoreLoading}
        renderActions={() => (
          <SelectWrapper>
            <Select
              name="sortBy"
              value={sortType}
              onChange={(e) => setSortType(Number(e.target.value))}
            >
              {
                applicableSortTypeOptions.map((sortTypeOption) => (
                  <option
                    key={sortTypeOption}
                    value={sortTypeOption}
                  >
                    {`${sortByText}: ${sortOptions[sortTypeOption]}`}
                  </option>
                ))
              }
            </Select>
          </SelectWrapper>
        )}
        renderResults={!isTours ? () => (
          <CampsiteListAndPriceQuery
            {...variables}
            sortType={sortType}
            activePin={typeof activePin === 'number' ? undefined : activePin} // If a previous tour pin is activePin it will break this search.
            onQueryChange={onQueryChange}
            onResultClick={onResultClick}
            setCount={setCount}
            setTotal={setTotal}
            onListAndPriceUpdate={onResultsUpdate}
            toggleBasket={toggleBasket}
            onProductTypeMismatch={onProductTypeMismatch}
            isOverseas={!!isOverseas}
            eventType={Number(eventType) || undefined}
            isTours={isTours}
            searchBy={searchBy}
            page={page}
            setLoading={setViewMoreLoading}
            isMobile={isMobile}
            resultsListRef={resultsContainerRef}
            nextPage={nextPage}
          />
        ) : () => (
          <CampsiteTours
            {...variables}
            toggleBasket={toggleBasket}
            onNearby={handleNearby}
            onProductTypeMismatch={onProductTypeMismatch}
            onResultClick={onResultClick}
            activePin={activePin}
            tours={result}
            loading={false}
            query={query}
            isMobile={isMobile}
          />
        )}
        renderSummary={renderSummaryProps => showResults && (
          <CampsiteSearchSummary
            handleChangeSearch={handleChangeSearch}
            handleFiltersClick={handleFiltersClick}
            handleClearUkItinerary={handleClearUkItinerary}
            isMobileMapOpen={isMobileMapOpen}
            query={query}
            renderSearchForm={() => renderSearchForm({ ...variables })}
            isTours={isTours}
            mapMovedCount={mapMovedCount}
            {...renderSummaryProps}
          />
        )}
      />
    </Body>
  );
}

CampsiteResults.propTypes = propTypes;
CampsiteResults.defaultProps = defaultProps;

export default memo(compose(
  withApollo,
  graphql(GET_CAMPSITE_SORT_TYPE),
  graphql(GET_SITES_CONFIG, {
    props: ({ data }) => ({
      sitesConfig: data.configurationSites,
    }),
  }),
)(CampsiteResults));
