import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { graphql, compose } from 'react-apollo';
import { withRouter } from 'next/router';

// Utils
import { format } from 'date-fns';
import buildPath from '../../lib/helpers/restLink';
import queryHelper from '../../lib/queryHelper';
import { dictionaryItem, getDictionaryItem } from '../../hocs/withDictionary';
import { DATE_FORMAT_DISPLAY } from '../../config/locale';

import svgOffer from '../../static/images/icons/Offer.svg';

// Styles
import StyledDrawerInfoWindow, {
  DrawerWrapper,
  DrawerContent,
  CampsiteHeader,
  CampsiteTitle,
  CampsiteContent,
  CampsiteImg,
  Image,
  FromToDate,
  Footer,
  OfferNote,
  OfferTitle,
  MoreDetails,
  Close,
  Img,
} from './DrawerInfoWindow.style';

// Get queries
import GET_CAMPSITE_PRICE, { campsitePriceOptions } from '../../config/graphql/getCampsitePrice';
import GET_CAMPSITE_LISTING from '../Listing/graphql/getCampsiteListing';
import { UPDATE_CURRENT_CAMPSITE } from '../../config/graphql';

import IbePropTypes from '../../IbePropTypes';
import { shouldSkipCampsiteListing } from '../Search/CampsiteListAndPriceQueryHelpers';
import GET_POI, { getPoiOptions } from './graphql/getPoi';
import GET_PIN, { getPinOptions } from './graphql/getPin';
import theme from '../../styles/config/theme';
import zIndex from '../../config/zIndex';

import { navigateTo, POST_TYPES } from '../../lib/helpers/navigation';
import { campsiteImageMapInfoKeys, mapAlternativeImages } from '../Picture/pictureHelper';
import Picture from '../Picture';

/**
 * We are handling data for the rendering of Tour list items and Campsite List items,
 * hence the checking for different id types.
 * this allows us to mirror the data requests and re use the Listing component,
 * to be honest this should be refactored.
 */
class DrawerInfoWindow extends PureComponent {
  static propTypes = {
    campsiteListing: PropTypes.shape({
      data: PropTypes.arrayOf(PropTypes.shape(IbePropTypes.campsiteListingItem)),
    }),
    campsiteName: PropTypes.string,
    error: PropTypes.shape({}),
    tour: PropTypes.shape(IbePropTypes.tour),
    isTours: PropTypes.bool.isRequired,
    lat: PropTypes.string,
    lng: PropTypes.string,
    campsiteId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    updateCurrentCampsite: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    pricesData: PropTypes.shape({
      networkStatus: PropTypes.number,
      campsitePrice: PropTypes.oneOfType([PropTypes.number, PropTypes.shape({
        data: PropTypes.arrayOf(PropTypes.shape(IbePropTypes.price)),
      })]),
    }),
    zoomLevel: PropTypes.number,
    zoomTo: PropTypes.number,
    isPoi: PropTypes.bool,
    pois: PropTypes.shape({
      poi: PropTypes.arrayOf(PropTypes.shape(IbePropTypes.poi)),
    }),
    client: PropTypes.shape(IbePropTypes.client),
    isVisible: PropTypes.bool.isRequired,
    handleToggleDrawer: PropTypes.func.isRequired,
    data: PropTypes.shape({
      error: PropTypes.shape({}),
      loading: PropTypes.bool,
      pinInfo: PropTypes.shape(IbePropTypes.pin),
    }),
  };

  static defaultProps = {
    campsiteName: '',
    error: null,
    lat: '',
    lng: '',
    campsiteListing: undefined,
    pricesData: {
      networkStatus: 0,
      campsitePrice: 0,
    },
    tour: undefined,
    zoomLevel: 0,
    zoomTo: 0,
    isPoi: false,
    pois: {
      poi: [],
    },
    client: null,
    loading: false,
    data: {
      error: null,
      loading: false,
      pinInfo: {
        events: [],
        id: '',
        isoPlaceCode: '',
        name: '',
        numberOfReviews: 0,
        rating: 0,
        type: 0,
      },
    },
  };

  componentDidMount() {
    this.props.updateCurrentCampsite({
      id: String(this.props.campsiteId),
      name: this.props.campsiteName,
      lat: this.props.lat,
      lng: this.props.lng,
      zoomLevel: this.props.zoomLevel,
      zoomTo: this.props.zoomTo,
    });
  }

  renderImage = (galleryItem, alt) => {
    if (!galleryItem) {
      return <></>;
    }

    const galleryImage = mapAlternativeImages(
      galleryItem.url,
      galleryItem.alternativeFormats,
      campsiteImageMapInfoKeys,
    );

    if (!galleryImage) {
      // maintain compatibility with components that don't utilize new optimized image structure
      return <Img
        alt={alt}
        src={galleryItem || ''}
        loading="eager"
      />;
    }

    return (
      <Picture image={galleryImage}>
        <Img
          alt={alt}
          src={galleryImage.baseUrl || ''}
          loading="eager"
        />
      </Picture>
    );
  }

  render() {
    if (this.props.loading ||
      this.props.pricesData.networkStatus === 1
    ) {
      return (
        <></>
      );
    }

    if (!this.props.campsiteId || this.props.error) {
      return (
        Error
      );
    }

    const poiData = this.props.pois?.poi?.find(
      pointOfInterest => pointOfInterest.id === this.props.campsiteId,
    );

    const campsiteData = this.props.data?.pinInfo || this.props.tour;

    const isPoi = this.props.isPoi || poiData;
    const poiImageGallery = poiData?.image ? [poiData?.image] : [];
    const gallery = isPoi ? poiImageGallery : campsiteData?.gallery;
    const name = isPoi ? poiData.name : campsiteData?.name;

    if (!isPoi) return null;

    return (
      <>
        <DrawerWrapper
          zIndex={zIndex.BASKET_WRAPPER_OPEN}
        >
          <StyledDrawerInfoWindow
            direction="right"
            full
            offset={theme.BASKET_TOGGLE_WIDTH}
            open={this.props.isVisible}
            overlay
          >
            <DrawerContent>
              <Close
                crossWidth="0.875rem"
                onClick={() => this.props.handleToggleDrawer()}
                width="1.5rem"
                height="1.5rem"
              />
              <CampsiteHeader>
                {((isPoi || this.props.isTours) && gallery?.length > 0) &&
                  <CampsiteImg>
                    <Image>
                      {this.renderImage(gallery[0], name)}
                    </Image>
                  </CampsiteImg>
                }

                <CampsiteTitle>
                  {name}<br />
                  {this.props.isTours &&
                    <>
                      {this.props.tour?.numberOfNights} {getDictionaryItem(this.props.client, 'InfoWindow__ToursNightsSuffix__Text')}
                    </>}
                </CampsiteTitle>
              </CampsiteHeader>

              <>
                <FromToDate>
                  {format(new Date(poiData?.startDateTime), DATE_FORMAT_DISPLAY)}
                  {' - '}
                  {format(new Date(poiData?.endDateTime), DATE_FORMAT_DISPLAY)}
                </FromToDate>
                <CampsiteContent>
                  {poiData?.promotionalText}
                </CampsiteContent>
              </>
            </DrawerContent>
            <Footer>
              {poiData?.offerTitle && (
                <>
                  <OfferNote
                    align="center"
                    icon={svgOffer}
                    dictionary={dictionaryItem('InfoWindow', 'OffersAvailable')}
                  />
                  <OfferTitle
                    align="center"
                    text={poiData.offerTitle}
                    marginTop
                    bold
                  />

                  <MoreDetails
                    size="medium"
                    hasOffers
                    onClick={() => navigateTo(poiData.poIUrl, {
                      type: POST_TYPES.NEW_WINDOW,
                    })}
                    dictionary={dictionaryItem('InfoWindow', 'ClaimOffer')}
                  />
                </>
              )}
            </Footer>
          </StyledDrawerInfoWindow>
        </DrawerWrapper>
      </>
    );
  }
}

export default compose(
  withRouter,
  queryHelper(GET_CAMPSITE_LISTING, (props) => {
    const listingVariables = {
      ...props.searchVariables,
      page: 0,
      pageSize: 20,
      skip: shouldSkipCampsiteListing(props) || typeof props.activePin === 'number',
    };

    return {
      notifyOnNetworkStatusChange: true,
      name: 'listingData',
      variables: {
        pathBuilder: buildPath('campsite/search'),
        ...listingVariables,
        skip: props.isTours,
      },
    };
  }),
  graphql(GET_CAMPSITE_PRICE, {
    name: 'pricesData',
    props: (data) => ({
      loadingPrices: data.loading,
      ...data,
    }),
    options: campsitePriceOptions,
    skip: ({ isTours, campsiteId }) => {
      // skip as may be set to a tour id
      const isNotGenuineCampsiteId = !Number.isNaN(Number(campsiteId)) && campsiteId !== undefined;
      return !!isTours || isNotGenuineCampsiteId;
    },
  }),
  graphql(UPDATE_CURRENT_CAMPSITE, {
    props: ({ mutate }) => ({
      updateCurrentCampsite: campsiteInfo => mutate({ variables: campsiteInfo }),
    }),
  }),
  graphql(GET_PIN, { options: getPinOptions }),
  graphql(GET_POI, { options: getPoiOptions, name: 'pois' }), // cached poi data, if campsite this is skipped
)(DrawerInfoWindow);
