export const campsiteImageKeys = {
  mobile: [
    'IBE_375x211',
    'IBE_860x484',
    'IBE_1290x726',
  ],
  mobileLarge: [
    'IBE_430x242',
  ],
  tablet: [
    'IBE_768x432',
    'IBE_1536x864',
  ],
  desktop: [
    'IBE_600x338',
    'IBE_1200x675',
    'IBE_1800x1014',
  ],
};

export const campsiteImageMapInfoKeys = {
  mobile: [
    'IBE_256x154',
    'IBE_430x242',
  ],
  mobileLarge: [
    'IBE_256x154',
    'IBE_430x242',
  ],
  tablet: [
    'IBE_256x154',
    'IBE_430x242',
  ],
  desktop: [
    'IBE_256x154',
    'IBE_430x242',
  ],
};

export const campsiteImageThumbKeys = {
  mobile: [
    'IBE_96x58',
    'IBE_192x116',
  ],
  mobileLarge: [
    'IBE_128x77',
    'IBE_256x154',
  ],
  tablet: [],
  desktop: [
    'IBE_160x96',
    'IBE_320x192',
  ],
};

export const mapAlternativeImages = (baseUrl, alternativeUrls, imageKeys) => {
  if (!baseUrl) return undefined;

  return {
    baseUrl,
    ...(alternativeUrls?.length > 0 ? {
      alternativeUrls: {
        mobile: alternativeUrls
          .filter(i => imageKeys.mobile.includes(i.format))
          .map(i => `${i.url} ${i.format.split('x').pop()}w`),
        mobileLarge: alternativeUrls
          .filter(i => imageKeys.mobileLarge.includes(i.format))
          .map(i => `${i.url} ${i.format.split('x').pop()}w`),
        tablet: alternativeUrls
          .filter(i => imageKeys.tablet.includes(i.format))
          .map(i => `${i.url} ${i.format.split('x').pop()}w`),
        desktop: alternativeUrls
          .filter(i => imageKeys.desktop.includes(i.format))
          .map(i => `${i.url} ${i.format.split('x').pop()}w`),
      },
    } : {}),
  };
};

export default null;
