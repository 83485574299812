import React, { PureComponent } from 'react';
import { withRouter } from 'next/router';
import PropTypes from 'prop-types';
import { geocodeByPlaceId } from 'react-places-autocomplete';
import { compose } from 'react-apollo';

import {
  canBoundsFitInToKmDiameter,
  convertMilesToKm,
  parseBounds,
  scaleBoundsToFitIntoKmDiameter,
} from '../../lib/query';
import { getMapBoundsRestriction } from '../../lib/helpers/map';

import withGoogle from '../../hocs/withGoogle';
import testingAttr from '../../lib/testingAttr';
import keyCode from '../../lib/keyCode';

import PlacesAutocomplete from './PlacesAutocomplete';

import {
  Button,
  Container,
  List,
  Wrapper,
} from './Typeahead.style';
import { SearchInput } from '../Search/CampsiteSearchForm.style';
import IbePropTypes from '../../IbePropTypes';
import { LOCATION_SEARCH_COORDINATES_IN_SESSION } from '../../lib/constants';
import { addTiming, startTiming, types } from '../../lib/timings';
import { setStorageItemList } from '../../lib/storage';
import { adjustBoundsToFitMap } from '../CampsiteMap/helpers';

class PlacesTypeahead extends PureComponent {
  static propTypes = {
    excludeGeoLocationTypes: PropTypes.arrayOf(PropTypes.string),
    children: PropTypes.node.isRequired,
    minLocationSearchRadiusMilesDecimal: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    onSelect: PropTypes.func.isRequired,
    router: PropTypes.shape(IbePropTypes.router).isRequired,
    scriptReady: PropTypes.bool.isRequired,
    value: PropTypes.string,
    showResults: PropTypes.bool,
  }

  static defaultProps = {
    excludeGeoLocationTypes: undefined,
    value: '',
    showResults: false,
  }

  static getDerivedStateFromProps(props, state) {
    const { value } = props;
    if (!value) return { value: '' };
    return { value };
  }

  constructor(props) {
    super(props);

    this.activeIndex = 0;

    this.state = {
      bounds: undefined,
      value: props.value,
      activeIndex: -1,
    };
  }

  componentDidMount(prevProps) {
    this.checkToInitializeSearchBounds();
  }

  componentDidUpdate(prevProps) {
    this.checkToInitializeSearchBounds();
  }

  checkToInitializeSearchBounds = () => {
    if (this.props.scriptReady && !this.state.bounds) {
      // eslint-disable-next-line react/no-did-update-set-state
      const bounds = getMapBoundsRestriction();
      this.setState({ bounds });
    }
  }

  handleChange = (location) => {
    this.props.onChange({ location });
  }

  handleArrowInput = async newIndex => this.setState({ activeIndex: newIndex })

  handleKeyDown = (event, suggestionsLen) => {
    if (event.which === keyCode.arrowDown) {
      // when you are on the last item, and click arrow down
      // new selected item will be first item in the list.
      if (this.state.activeIndex >= suggestionsLen - 1) {
        this.handleArrowInput(0);
        return;
      }
      // increase list index by 1;
      this.handleArrowInput(this.state.activeIndex + 1);
    }

    if (event.which === keyCode.arrowUp) {
      // when you are on the first item, and click arrow down
      // new selected item will be last item in the list.
      if (this.state.activeIndex <= 0) {
        this.handleArrowInput(suggestionsLen - 1);
        return;
      }
      // decrease list index by 1;
      this.handleArrowInput(this.state.activeIndex - 1);
    }
  }

  handleSelect = (address, placeId) => {
    startTiming(types.GEOCODE);

    geocodeByPlaceId(placeId)
      .then(results => {
        let defaultResult = results.find(r => r.place_id === placeId);
        if (!defaultResult) {
          [defaultResult] = results;
        }

        const coords = {
          lat: defaultResult?.geometry?.location?.lat(),
          lng: defaultResult?.geometry?.location?.lng(),
        };

        setStorageItemList(LOCATION_SEARCH_COORDINATES_IN_SESSION, address, coords);

        let bounds = defaultResult?.geometry?.viewport;

        // if min radius to frame is below min frame then get minimum bounds.
        const kmDiameter = convertMilesToKm(this.props.minLocationSearchRadiusMilesDecimal);

        if (canBoundsFitInToKmDiameter(bounds, kmDiameter)) {
          const updatedBounds = scaleBoundsToFitIntoKmDiameter(bounds, kmDiameter);
          bounds = updatedBounds;
        }

        const isBookingWidget = this.props.router?.query?.bookingWidget === 'true';
        if (!isBookingWidget) {
          // expands bounds to match map aspect ratio
          bounds = adjustBoundsToFitMap(bounds);
        }
        return { bounds: parseBounds(bounds), coords, defaultResult };
      })
      // calls handleLocationSelect in CampsiteSearch
      .then(result => this.props.onSelect({
        ...result.bounds,
        coords: result.coords,
        location: address,
        locationType: result.defaultResult?.types?.length > 0
          ? result.defaultResult.types[0]
          : null,
        placeId,
      }))
      .then(results => {
        // TEMP Performance Hooks, TODO can be safely removed at any time
        addTiming(types.GEOCODE, 'GeocodeByPlaceId Complete', true);
      })
      .catch(error => console.error('Error', error));
  }

  render() {
    if (!this.props.scriptReady) return <SearchInput block size="large" />;

    const isOverseas = this.props.router?.query?.isOverseas === 'true';

    return (
      // unfortunately strictbounds is not supported on AutocompleteService
      <PlacesAutocomplete
        debounce={1000}
        excludeGeoLocationTypes={this.props.excludeGeoLocationTypes}
        value={this.state.value}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
        searchOptions={{
          locationRestriction: this.state.bounds,
          types: ['geocode', 'establishment'],
          componentRestrictions: (isOverseas ? { country: undefined } : { country: 'gb' }),
        }}
        shouldFetchSuggestions={this.state.value.length >= 2}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps }) => (
          <Container>
            {React.Children.map(this.props.children, child => (
              React.cloneElement(child, {
                ...getInputProps({ placeholder: 'Location' }),
                ...testingAttr('search__location-input'),
                onKeyDown: event => this.handleKeyDown(event, suggestions.length),
              })
            ))}
            <Wrapper inneRef={this.wrapperRef}>
              {!!suggestions.length && this.props.showResults &&
                <List>
                  {suggestions.map((suggestion, index) => (
                    <li key={suggestion.id} {...getSuggestionItemProps(suggestion)}>
                      <Button
                        active={this.state.activeIndex === index}
                        {...testingAttr(`search__typeahead-btn-${suggestion.placeId}`)}
                        data-e2e-description={suggestion.description}
                      >
                        {suggestion.description}
                      </Button>
                    </li>
                  ))
                  }
                </List>
              }
            </Wrapper>
          </Container>
        )}
      </PlacesAutocomplete>
    );
  }
}

export default compose(
  withGoogle,
  withRouter,
)(PlacesTypeahead);
