import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { withApollo } from 'react-apollo';
import { useQuery } from '@apollo/client';
import { isEqual } from 'lodash';
import { isAfter, isBefore } from 'date-fns';

// Utils
import buildPath from '../../lib/helpers/restLink';
import { livePrice } from '../Price/PriceCampsiteQuery';

import CampsiteMapMarkers from './CampsiteMapMarkers';

// Get queries
import GET_CAMPSITES from './graphql/getCampsites';
import GET_POI from './graphql/getPoi';
import GET_PRICES, {
  normalizeListingVariablesForConsistentPriceCache,
  normalizePriceVariablesForConsistentPriceCache,
} from '../../config/graphql/getPrices';
import GET_CAMPSITE_LISTING from '../Listing/graphql/getCampsiteListing';
import PLACE_EVENT_TYPES from '../../config/eventTypes';
import IbePropTypes from '../../IbePropTypes';

import FetchPolicy from '../../constants/FetchPolicy';
import getFetchPolicyWithExpiry from '../../lib/getFetchPolicyWithExpiry';
import { addTypesToToursQuery, filterLocations } from '../../lib/helpers/tours';
import GET_CAMPSITE_SORT_TYPE from '../../config/graphql/getCampsiteSortType';
import {
  getLocationCoordinatesInStorage,
  mergeLocationsListingAndPrices,
} from '../Search/CampsiteListAndPriceQueryHelpers';
import { searchByType } from '../../constants/search';
import { parseTypes } from '../../lib/campsiteTypes';
import SafeQuery from '../SafeQuery/SafeQuery';

// buffer applied to prices query to get prices of markers
// whose pin is just outside map bounds but prices still
// visible
const MAP_QUERY_BUFFER = 0.1;

export const DEFAULT_POI_ZOOM_LEVEL = 14;
const DEFAULT_SITE_ZOOM_LEVEL = 14;
const MAX_POI_ZOOM_LEVEL = 22;
const EXPIRY_POI_API_CACHE_MS = 1 * 60 * 60 * 1000;

function filterVisiblePois(
  pois,
  start = null,
  end = null,
  zoomLevel = MAX_POI_ZOOM_LEVEL,
  poiFilters,
) {
  if (!pois) {
    return [];
  }

  return pois.filter(poi => {
    const poiZoomLevel = poi.zoomLevel || DEFAULT_POI_ZOOM_LEVEL;
    const withinZoomLevel = poiZoomLevel <= zoomLevel;

    let withinDateRange = true;
    if (start && end) {
      withinDateRange =
      isBefore(new Date(poi.startDateTime), new Date(end))
        && isAfter(new Date(poi.endDateTime), new Date(start));
    }

    // TODO add filtering by user.
    const isFilteredByUser = !!poiFilters.length &&
      poiFilters.reduce((prev, cur) => {
        const hasFeature =
          poi?.features?.find(feature => feature.id === cur);
        return hasFeature || prev;
      }, false);

    return isFilteredByUser ? withinDateRange : withinZoomLevel && withinDateRange;
  });
}

const pagination = { page: 0, pageSize: 1000 };

function MapMarkers({
  selectedEvent,
  getMapBounds,
  features,
  types: typesString,
  poiFilters,
  client,
  ...props
}) {
  // types parsed back to an array again, after being a string for memo
  // memoized here too to stop it re-rendering the markers in the child
  const types = useMemo(() => parseTypes(typesString.split(',')), [typesString]);

  const { data: { campsiteSortType: sortType } } = useQuery(GET_CAMPSITE_SORT_TYPE, { client });

  const query = {
    types,
    features,
    suggestedUkItineraryId: props.ukItinerary,
  };
  const updatedQuery = addTypesToToursQuery(query);
  return (
    <SafeQuery
      query={GET_CAMPSITES}
      variables={{
        pathBuilder: buildPath('campsite/locations', pagination),
        features,
        types: updatedQuery?.types,
      }}
    >
      {(campsiteResponse) => {
        const campsiteLocations = campsiteResponse.data?.campsiteLocations;
        if (!campsiteLocations || !campsiteLocations.length) {
          return null;
        }

        const filteredLocations = filterLocations(
          campsiteLocations,
          props.suggestedUkItineraries,
          props.ukItinerary,
          props.eventType,
        );

        const mapBounds = new google.maps.LatLngBounds(
          new google.maps.LatLng(props.sw_lat, props.sw_lng),
          new google.maps.LatLng(props.ne_lat, props.ne_lng),
        );

        // TODO: Find the route cause of this running before map has rendered
        if (!mapBounds) return null;

        const count = filteredLocations.filter(({ lat, lon }) => mapBounds.contains(
          new google.maps.LatLng(lat, lon),
        )).length;

        // MapMarkers only attempts to get prices when there are less that 20 results, or area
        // bounds are not provided
        const invalidCount = !count || count > 20 || !props.sw_lat;

        const sharedPriceAndListingVariables = {
          bottomRightLat: props.sw_lat - MAP_QUERY_BUFFER,
          bottomRightLon: props.ne_lng + MAP_QUERY_BUFFER,
          features,
          page: 0,
          pageSize: 30,
          topLeftLat: props.ne_lat + MAP_QUERY_BUFFER,
          topLeftLon: props.sw_lng - MAP_QUERY_BUFFER,
          types: updatedQuery.types,
          ...livePrice(props.start, props.end),
          ...props.searchBy === searchByType.LOCATION && getLocationCoordinatesInStorage(),
        };

        // We must be providing the same params as used in CampsiteListAndPriceQuery
        // so the Apollo cacheKey is identical
        const campsitePricesVariables = {
          ...sharedPriceAndListingVariables,
          skip: invalidCount,
        };

        const campsiteListingVariables = {
          ...sharedPriceAndListingVariables,
          suggestedUkItineraryId: '',
          skip: invalidCount || (!props.start || !props.end),
        };
        //-

        return (
          <SafeQuery
            query={GET_POI}
            fetchPolicy={getFetchPolicyWithExpiry('GET_POI', {
              defaultPolicy: FetchPolicy.CACHE_FIRST,
              expiry: EXPIRY_POI_API_CACHE_MS,
              expiryPolicy: FetchPolicy.NETWORK_ONLY,
            })}
          >
            {({ data: poiData }) => {
              const { poi } = poiData;
              const pois = filterVisiblePois(
                poi,
                props.start,
                props.end,
                props.zoomLevel,
                poiFilters,
              );

              return (
                <SafeQuery
                  query={GET_CAMPSITE_LISTING}
                  variables={normalizeListingVariablesForConsistentPriceCache(
                    { sortType, ...campsiteListingVariables },
                  )}
                  skip={campsiteListingVariables.skip}
                >
                  {({ data: campsiteListingData }) => (
                    <SafeQuery
                      query={GET_PRICES}
                      variables={normalizePriceVariablesForConsistentPriceCache(
                        { sortType, ...campsitePricesVariables },
                      )}
                      skip={campsitePricesVariables.skip}
                    >
                      {({ data: priceData, error, loading }) => {
                        let results = [...filteredLocations];
                        // TODO: Handle pricing error
                        if (!error && priceData && priceData.prices && priceData.prices.data) {
                          results = mergeLocationsListingAndPrices(
                            filteredLocations,
                            campsiteListingData?.campsiteListing?.data,
                            [...priceData.prices.data],
                            props.eventType || PLACE_EVENT_TYPES.TOURING.id,
                            props.isOverseas,
                          );
                        }

                        return <CampsiteMapMarkers
                          activePin={props.activePin}
                          count={count}
                          features={props.features}
                          goToTop={props.goToTop}
                          isMobile={props.isMobile}
                          loading={loading}
                          onCampsiteSearch={props.onCampsiteSearch}
                          onMarkerClick={props.onMarkerClick}
                          onMoreDetailsClick={props.onMoreDetailsClick}
                          onQueryChange={props.onQueryChange}
                          pois={pois}
                          tours={props.tours}
                          isTours={props.isTours}
                          results={results}
                          types={types}
                          start={props.start}
                          end={props.end}
                          tourCode={props.tourCode}
                          ukItinerary={props.ukItinerary}
                          selectedEvent={selectedEvent}
                        />;
                      }}
                    </SafeQuery>
                  )}
                </SafeQuery>
              );
            }}
          </SafeQuery>
        );
      }}
    </SafeQuery>
  );
}

MapMarkers.propTypes = {
  activePin: PropTypes.string,
  client: PropTypes.shape(IbePropTypes.client).isRequired,
  end: PropTypes.string,
  goToTop: PropTypes.func,
  isMobile: PropTypes.bool.isRequired,
  features: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]),
  getMapBounds: PropTypes.func,
  onCampsiteSearch: PropTypes.func.isRequired,
  onMarkerClick: PropTypes.func,
  onMoreDetailsClick: PropTypes.func,
  onQueryChange: PropTypes.func.isRequired,
  ne_lat: PropTypes.number,
  ne_lng: PropTypes.number,
  start: PropTypes.string,
  sw_lat: PropTypes.number,
  sw_lng: PropTypes.number,
  searchBy: PropTypes.string,
  types: PropTypes.string,
  zoomLevel: PropTypes.number,
  selectedEvent: PropTypes.shape(IbePropTypes.event),
  poiFilters: PropTypes.arrayOf(PropTypes.string),
  eventType: PropTypes.string,
  isOverseas: PropTypes.bool,
  isTours: PropTypes.bool,
  suggestedUkItineraries: PropTypes.arrayOf(PropTypes.shape(IbePropTypes.ukItinerary)),
  tourCode: PropTypes.string,
  tours: PropTypes.arrayOf(PropTypes.shape(IbePropTypes.tour)),
  ukItinerary: PropTypes.string,
  // required for map to update after idle to render clusters consistently
  forceMapMarkerRenderUpdateKey: PropTypes.string,
};

MapMarkers.defaultProps = {
  activePin: '',
  end: null,
  features: [],
  getMapBounds() { },
  goToTop() { },
  onMarkerClick() { },
  onMoreDetailsClick() { },
  ne_lat: null,
  ne_lng: null,
  start: null,
  sw_lat: null,
  sw_lng: null,
  searchBy: searchByType.CAMPSITE,
  types: '',
  selectedEvent: null,
  poiFilters: [],
  zoomLevel: DEFAULT_SITE_ZOOM_LEVEL,
  eventType: PLACE_EVENT_TYPES.TOURING.id,
  isOverseas: false,
  isTours: false,
  suggestedUkItineraries: [],
  tourCode: '',
  tours: [],
  ukItinerary: '',
  forceMapMarkerRenderUpdateKey: '',
};

export default withApollo(memo(MapMarkers, (...args) => isEqual(args[0], args[1])));
