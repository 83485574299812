import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose, graphql, withApollo } from 'react-apollo';
import { withRouter } from 'next/router';

import getCampsiteType from '../../lib/campsiteTypes';
import updateRouterQuery from '../../lib/updateRouterQuery';
import { generateDateRange } from '../../lib/helpers/tours';
import { closeDetailsQuery } from '../../lib/campsiteSearch';
import scrollEvent from '../../lib/scrollEvent';

import theme from '../../styles/config/theme';

import { dictionaryItem, getDictionaryItem } from '../../hocs/withDictionary';

import PricesReviewsBookNow from './PricesReviewsBookNow';
import ListingResultHeader from './ListingResultHeader';
import Carousel from '../Carousel';
import Note from '../Note';
import ReadMore from '../ui/ReadMore';
import SiteCard from '../SiteCard';
import FlexTable, { FlexCell, FlexRow } from '../ui/FlexTable';
import Text from '../ui/Text';
import { Flex } from '../ui/Grid/Row.style';
import ListingHighlight from './ListingHighlight';
import { List } from '../CampsiteFeature/CampsiteFeature.style';
import ButtonBordered from '../ui/Button/ButtonBordered.style';

import ListingResultStyled, {
  Body,
  Title,
} from './ListingResult.style';

import svgArrowLeft from '../../static/images/icons/ArrowLeft.svg';
import svgArrowRight from '../../static/images/icons/ArrowRight.svg';

import scrollToTop from '../../lib/scrollToTop';
import IbePropTypes from '../../IbePropTypes';
import { ids as campsiteIds } from '../../config/campsiteTypes';
import routes from '../../constants/routes';
import { dataLayerManager } from '../../lib/dataLayer';
import { mapAlternativeImages } from '../Picture/pictureHelper';
import GET_SITES_CONFIG from '../../config/graphql/getSitesConfig';

const ListingResultTours = (props) => {
  const { onQueryChange } = props;

  const handleCloseDetails = () => {
    const query = closeDetailsQuery(props.query);

    updateRouterQuery(routes.sites, query);
    props.onQueryChange(query);

    setTimeout(() => {
      scrollEvent();
    }, 350);
  };

  const handleViewItineraryClick = () => {
    const { campsiteId, tours } = props;
    const tour = tours?.find((item) => item.id?.toString() === campsiteId);
    if (!tour) {
      return;
    }
    scrollToTop();
    onQueryChange({ tourCode: tour.id });
    updateRouterQuery(routes.sites, { tourCode: tour.id, reviews: false });
  };

  const {
    campsiteId,
    client,
    error,
    sitesConfig,
    toggleBasket,
    tours,
    tourCode,
  } = props;

  const campsiteType = getCampsiteType(
    sitesConfig.campsiteTypes,
    campsiteIds.OVERSEAS_TOUR,
  );

  const tour = tours.find((item) => item.id?.toString() === campsiteId);

  useEffect(() => {
    if (tour) {
      dataLayerManager.pushProductDetails(dataLayerManager.subCategory.EUROPEAN_TOUR, {
        ...tour,
        ...props.query,
        price: tour.fromPrice,
        subCategory: dataLayerManager.subCategory.EUROPEAN_TOUR,
      });
    }
  }, [tour]);

  if (error || !tour) {
    return (
      <ListingResultStyled>
        <SiteCard
          toggleBasket={toggleBasket}
          handleDrawerToggle={handleCloseDetails}
          scroll={false}
          type="back"
        >
          {getDictionaryItem(client, 'ListingItem__TourNotFound__Text')}
        </SiteCard>
      </ListingResultStyled>
    );
  }

  return (
    <ListingResultStyled inView={!tourCode}>
      <SiteCard
        handleDrawerToggle={handleCloseDetails}
        scroll={false}
        type="back"
        toggleBasket={toggleBasket}
      >
        <ListingResultHeader
          {...tour}
          numNightsSuffix={getDictionaryItem(client, 'ListingItem__ToursNightsSuffix__Text')}
          revealAddress={false}
          isTours
        />
        <Fragment>
          <PricesReviewsBookNow
            activeQuote={false}
            handleBookNowClick={() => { }}
            handleReviewsClick={() => { }}
            memberPrice={tour?.fromPrice}
            priceSuffix={tour?.fromPriceSuffix}
            siteCode={tour?.id}
            client={client}
            rating={0}
            campsiteType={campsiteIds.OVERSEAS_TOUR}
            isTours
          />

          <Carousel
            indicatorRight={svgArrowRight}
            indicatorLeft={svgArrowLeft}
            id={tour?.id}
            images={tour?.gallery?.map(g => mapAlternativeImages(g)) || []}
          />

          <Note
            backgroundColor={campsiteType.color}
            align="center"
            color={theme.COLOR_WHITE}
            text={campsiteType.text}
          />

          <Body>
            <Title
              dictionary={dictionaryItem(
                'ListingResult',
                'Overview',
              )}
              tag={2}
              size={4}
            />
            <ReadMore text={tour?.description} limit={265} />

            <ButtonBordered
              block
              onClick={handleViewItineraryClick}
              size="medium"
            >
              View Itinerary
            </ButtonBordered>

            <Title
              dictionary={dictionaryItem('ListingResult', 'TourHighlights')}
              tag={2}
              size={4}
            />
            <Flex>
              <List highlight>
                {tour?.tourHighlights?.map((tourHighlight) => (
                  <ListingHighlight key={tourHighlight} label={tourHighlight} small />
                ))}
              </List>
            </Flex>

            <Title
              dictionary={dictionaryItem('ListingResult', 'WhatsIncluded')}
              tag={2}
              size={4}
            />
            <Flex flexDirection="column">
              <Text marginBottom="1rem">{tour?.whatsIncludedIntroText}</Text>
              <List>
                {tour?.whatsIncluded?.map((whatsIncludedItem) => (
                  <ListingHighlight label={whatsIncludedItem} small check />
                ))}
              </List>
            </Flex>

            <Title
              dictionary={dictionaryItem('ListingResult', 'AvailableDates')}
              tag={2}
              size={4}
            />
            <FlexTable>
              {tour?.departureDates?.map((departureDate) => (
                <FlexRow key={departureDate}>
                  <FlexCell>
                    {generateDateRange(departureDate, tour?.numberOfNights)}
                  </FlexCell>
                </FlexRow>
              ))}
            </FlexTable>
          </Body>
        </Fragment>
      </SiteCard>
    </ListingResultStyled>
  );
};

ListingResultTours.propTypes = {
  tours: PropTypes.arrayOf(PropTypes.shape(IbePropTypes.tour)),
  client: PropTypes.shape(IbePropTypes.client).isRequired,
  sitesConfig: PropTypes.shape(IbePropTypes.sitesConfig).isRequired,
  error: PropTypes.shape(IbePropTypes.apolloError),
  query: PropTypes.shape(IbePropTypes.query),
  onQueryChange: PropTypes.func.isRequired,
  campsiteId: PropTypes.string,
  toggleBasket: PropTypes.func,
  tourCode: PropTypes.string,
};

ListingResultTours.defaultProps = {
  tours: [],
  error: null,
  query: null,
  campsiteId: '',
  toggleBasket: () => { },
  tourCode: '',
};

export default compose(
  withApollo,
  withRouter,
  graphql(GET_SITES_CONFIG, {
    props: ({ data }) => ({
      sitesConfig: data.configurationSites,
    }),
  }),
)(ListingResultTours);
