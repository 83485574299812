import { alwaysArray, toNumber, toString } from '..';
import { parseTypes } from '../campsiteTypes';

export default function Sites(query, defaultTypes) {
  return {
    ...query,
    end: toString(query.end),
    features: alwaysArray(query.features),
    poi_lat: toNumber(query.poi_lat),
    poi_lng: toNumber(query.poi_lng),
    ne_lat: parseFloat(query.ne_lat),
    ne_lng: parseFloat(query.ne_lng),
    start: toString(query.start),
    sw_lat: parseFloat(query.sw_lat),
    sw_lng: parseFloat(query.sw_lng),
    types: query.ukItinerary ? [] : parseTypes(query.types, defaultTypes),
    zoomLevel: Math.round(toNumber(query.zoomLevel)),
    userLat: toNumber(query.userLat),
    userLng: toNumber(query.userLng),
    userZoom: toNumber(query.userZoom),
    isOverseas: query.isOverseas || '',
    eventType: query.eventType || '',
  };
}
