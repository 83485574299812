import { compose, graphql } from 'react-apollo';
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import GET_SITES_CONFIG from '../../config/graphql/getSitesConfig';
import IbePropTypes from '../../IbePropTypes';

const propTypes = {
  sitesConfig: PropTypes.shape(IbePropTypes.sitesConfig).isRequired,
  type: PropTypes.number.isRequired,
};

function CampsiteType(props) {
  const { sitesConfig, type } = props;

  if (!sitesConfig.campsiteTypes.length) return null;

  const campsiteType = sitesConfig.campsiteTypes.find(t => t.key === type);

  if (!campsiteType) return null;

  return (
    <Fragment>
      {campsiteType.value}
    </Fragment>
  );
}

CampsiteType.propTypes = propTypes;

export default compose(
  graphql(GET_SITES_CONFIG, {
    props: ({ data }) => ({ sitesConfig: data.configurationSites }),
  }),
)(CampsiteType);
