/* eslint-disable no-underscore-dangle */
import React, { memo, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';
import { cloneDeep, isEqual } from 'lodash';

import alwaysArray from '../../lib/alwaysArray';
import connectionType from '../../lib/connectionType';
import { livePrice } from '../Price/PriceCampsiteQuery';

import LoadingSpinner from '../ui/Loading/LoadingSpinner';
import SearchResultsEmpty from '../SearchResults/SearchResultsEmpty';
import SiteNightVoucher from '../SiteNightVoucher';
import { ListingItem } from '../Listing';
import SpecialListingItem from '../Listing/SpecialListingItem';

import GET_CAMPSITE_LISTING from '../Listing/graphql/getCampsiteListing';
import GET_PRICES, { normalizeListingVariablesForConsistentPriceCache, normalizePriceVariablesForConsistentPriceCache } from '../../config/graphql/getPrices';
import GET_CONFIGURATION from '../../config/graphql/getConfiguration';

import { dictionaryItem } from '../../hocs/withDictionary';
import { dataLayerManager } from '../../lib/dataLayer';
import { addTypesToToursQuery } from '../../lib/helpers/tours';
import { sortCampsites } from '../../lib/campsiteSearch';
import { ids as campsiteTypeIds } from '../../config/campsiteTypes';
import PLACE_EVENT_TYPES, { SINGLE_EVENT_TYPE } from '../../config/eventTypes';
import { addTiming, hasMetric, types as timingTypes } from '../../lib/timings';
import { searchByType } from '../../constants/search';
import FetchPolicy from '../../constants/FetchPolicy';
import getFetchPolicyWithExpiry from '../../lib/getFetchPolicyWithExpiry';
import {
  getDefaultPlaceEvent,
  getCampsiteListing,
  getLocationCoordinatesInStorage,
  getPrices,
  mergeListingAndPrices,
  shouldSkipCampsiteListingIfInvalidDates,
} from './CampsiteListAndPriceQueryHelpers';
import { checkOpen } from '../../lib/helpers/availability';

import { SearchResultsWrapper } from './Search.style';
import { scrollToActive } from '../../lib/helpers/campsites';
import ViewMoreResults from '../SearchResults/ViewMoreResults';

/**
 * Function component used purely to detect valid conditions after render whereupon we want to
 * scroll to the active campsite in the listing. This occurs after a completed listing load,
 * change of search and only on the initial page.
 */
function CheckToScrollToActive({
  activePin,
  callScrollToActive,
  complete,
  page,
  searchCacheKey,
}) {
  useEffect(() => {
    if (complete && page === 0 && activePin) callScrollToActive();
  }, [complete, page, searchCacheKey, activePin]);

  return null; // nothing to render
}

function CampsiteListAndPriceQuery(props) {
  const queryTypes = alwaysArray(props.types);

  const {
    topLeftLat,
    topLeftLon,
    bottomRightLat,
    bottomRightLon,
    features,
    toggleBasket,
    types,
    start,
    end,
    sortType,
    onProductTypeMismatch,
    suggestedUkItineraryId,
    isOverseas,
    eventType,
    isTours,
    searchBy,
    page,
    setTotal,
    setLoading,
    activePin,
    isMobile,
    resultsListRef,
    nextPage,
  } = props;

  const listingVariables = {
    topLeftLat,
    topLeftLon,
    bottomRightLat,
    bottomRightLon,
    features,
    types,
    page,
    pageSize: connectionType(),
    start,
    suggestedUkItineraryId,
    end,
    skip: shouldSkipCampsiteListingIfInvalidDates(props),
    sortType,
  };

  const imagesLoadedRef = useRef([]);
  const previous = useRef([]);
  // performant method of detecting change of search api call in render
  const searchNetworkStatusRef = useRef(0);
  // performant method of detecting change of prices api call in render
  const pricesNetworkStatusRef = useRef(0);
  // performant method of detecting change of search params in render
  const searchCacheKeyRef = useRef('');

  const paginationStoreRef = useRef({});

  const divWrapperRef = useRef(null);

  useEffect(() => {
    scrollToActive(resultsListRef, isMobile, activePin);
  }, [activePin, isMobile]);

  /**
   * Listing results due to pagination need to be stored. We simply store the data synchronously in
   * a ref. The listing and price query will only get 20 at a time, therefore we need the below
   * logic to retain previous paginated result in a store. We store the results per page, but the
   * results are flattened when required to be rendered. The store keeps each API result against
   * its page number as a key. The store is emptied if the query string changes at all. The store
   * is continually updated per render, this allow changes in results like the update of price to
   * feed through and be saved in the store.
   *
   * @param {Array} results
   * @param {number} searchNetworkStatus
   */
  const updatePaginationResultsStore = (results, searchNetworkStatus) => {
    // create cacheKey, if the parameter is not in here, the listing will not detect change.
    const queryCacheKey = [
      topLeftLat,
      topLeftLon,
      bottomRightLat,
      bottomRightLon,
      sortType,
      types?.toString(),
      features?.toString(),
    ].join('|');

    const pageAsKey = String(page);

    const cache = paginationStoreRef.current || {};
    const hasQueryCacheKeyChanged = !Object.values(cache).every(
      c => c.queryCacheKey === queryCacheKey,
    );

    const searchDataHasLoaded = searchNetworkStatus === 7;

    if (searchDataHasLoaded && paginationStoreRef.current) {
      if (page === 0 && hasQueryCacheKeyChanged) {
        // clear the cache across all pages if there are changes to the query and on first page
        paginationStoreRef.current = {};
      }
      // set results into store
      paginationStoreRef.current[pageAsKey] = { queryCacheKey, results };
    }
  };

  /**
   * Simply returns all the results stored in the pagination cache, this is what is rendered in the
   * listing
   * @returns Array of results
   */
  const getPaginationResultsFromStore = () => {
    const pagesResultsCache = Object.values(paginationStoreRef.current || []);
    return pagesResultsCache.reduce((acc, singlePageResultsCache) => (
      acc.concat(singlePageResultsCache.results || [])
    ), []);
  };

  const showOverseasSiteNightVoucher = queryTypes.includes(campsiteTypeIds.OVERSEAS_SITE)
    && isOverseas && !isTours;

  const sortByFeatured = arr => arr.sort((a, b) => {
    const matching = a.isFeatured === b.isFeatured;
    const place = a.isFeatured ? -1 : 1;
    return matching ? 0 : place;
  });

  const filterEventType = (campsites, eventTypeId) => campsites.filter(
    campsite => !!campsite.placeEvent?.find(
      (placeEventItem) => (placeEventItem.eventType === eventTypeId) ||
        (eventTypeId === PLACE_EVENT_TYPES.TOURING.id &&
          placeEventItem.eventType === SINGLE_EVENT_TYPE.id),
      // Include single event type if touring is selected
    ),
  );

  const onLoadImage = (id) => {
    // TEMP Performance Hooks, TODO can be safely removed at any time
    if (imagesLoadedRef.current) {
      imagesLoadedRef.current.push(id);
      const allLoaded = previous.current?.every((p) => imagesLoadedRef.current.includes(p.id));
      if (allLoaded) {
        addTiming(timingTypes.SEARCH_LOCATION, 'Results Images Complete', true);
        const isComplete = hasMetric(timingTypes.MAP_ZOOM, 'Tiles Loaded');
        addTiming(timingTypes.MAP_ZOOM, 'Results Image Loaded');
        if (isComplete) {
          addTiming(timingTypes.MAP_ZOOM, 'Complete', isComplete);
        }
      }
    }
  };

  const checkToAddImpressions = async (client, results, priceData) => {
    // TEMP Performance Hooks, TODO can be safely removed at any time
    addTiming(timingTypes.SEARCH_LOCATION, 'Results Prices Loaded');

    const isTilesComplete = hasMetric(timingTypes.MAP_ZOOM, 'Tiles Loaded');
    const isResultsComplete = hasMetric(timingTypes.MAP_ZOOM, 'Results Loaded');
    const arePricesLoading = hasMetric(timingTypes.MAP_ZOOM, 'Results Prices Loading');

    // we only want to record a price loaded after we know a loading has happened
    if (arePricesLoading) {
      addTiming(timingTypes.MAP_ZOOM, 'Results Prices Loaded');
    }

    const isPricesComplete = hasMetric(timingTypes.MAP_ZOOM, 'Results Prices Loaded');
    const isComplete = isTilesComplete && isResultsComplete && isPricesComplete;
    if (isComplete) {
      addTiming(timingTypes.MAP_ZOOM, 'Complete', isComplete);
    }
    let impressionData = [];

    // perform merge of price and listing data as in render function
    const prices = getPrices(cloneDeep(priceData));
    const mergedResults = mergeListingAndPrices(
      results,
      [...prices.data],
      eventType,
      isOverseas,
    );
    const sortedResults = sortCampsites(mergedResults, sortType, queryTypes);

    // get site night voucher data from configuration
    const { data } = await client.query({ query: GET_CONFIGURATION });

    // only add Site Night Voucher if we have results, data here is formatted
    // to be the same as a campsite so its able to be processed with other
    // campsites.
    if (sortedResults.length) {
      if (showOverseasSiteNightVoucher) {
        impressionData.push({
          id: dataLayerManager.bespokeProductIds.OVERSEAS_SITE_NIGHT_VOUCHERS,
          name: data.configuration.siteNightVoucherInfo.titleText,
          memberPrice: data.configuration.siteNightVoucherInfo.unitCost,
          category: dataLayerManager.category.OVERSEAS,
          subCategory: dataLayerManager.subCategory.VOUCHER,
        });
      }
    }

    // Add results
    impressionData = impressionData.concat(sortedResults);
    if (suggestedUkItineraryId) {
      impressionData = impressionData.map(
        (item) => ({
          ...item,
          subCategory: dataLayerManager.subCategory.UK_ITINERARY,
          ukItinerary: suggestedUkItineraryId,
        }),
      );
    }
    props.onListAndPriceUpdate(impressionData);
  };

  const updateAndCheckSearchAndPricesComplete = (
    searchNetworkStatus,
    pricesNetworkStatus,
    client,
    results,
    priceData,
    searchCacheKey,
  ) => {
    if (
      searchNetworkStatus !== searchNetworkStatusRef.current
      || pricesNetworkStatus !== pricesNetworkStatusRef.current
      || searchCacheKey !== searchCacheKeyRef.current
    ) {
      searchNetworkStatusRef.current = searchNetworkStatus;
      pricesNetworkStatusRef.current = pricesNetworkStatus;
      searchCacheKeyRef.current = searchCacheKey;

      if (searchNetworkStatus === 7 && pricesNetworkStatus === 7) {
        checkToAddImpressions(client, results, priceData);
        return true;
      }
    }
    return false;
  };

  // We must be providing the same params as used in MapMarkers
  // so the Apollo cacheKey is identical
  const campsiteListingVariables = normalizeListingVariablesForConsistentPriceCache({
    ...listingVariables,
    ...searchBy === searchByType.LOCATION && getLocationCoordinatesInStorage(),
  });

  const campsitePricesVariables = normalizePriceVariablesForConsistentPriceCache({
    ...addTypesToToursQuery(listingVariables),
    ...livePrice(listingVariables.start, listingVariables.end),
    ...searchBy === searchByType.LOCATION && getLocationCoordinatesInStorage(),
  });

  const searchCacheKey = `GET_CAMPSITE_LISTING${JSON.stringify(campsiteListingVariables)}`;
  const pricesCacheKey = `GET_CAMPSITE_PRICES${JSON.stringify(campsitePricesVariables)}`;

  return (
    <SearchResultsWrapper ref={divWrapperRef}>
      <Query
        fetchPolicy={FetchPolicy.CACHE_AND_NETWORK}
        notifyOnNetworkStatusChange
        onCompleted={({ campsiteListing, ...others }) => {
          setTotal(campsiteListing?.count || 0);
          setLoading(false);
          // TEMP Performance Hooks, TODO can be safely removed at any time
          addTiming(timingTypes.SEARCH_LOCATION, 'Results Loaded');
          addTiming(timingTypes.MAP_ZOOM, 'Results Loaded');
        }}
        query={GET_CAMPSITE_LISTING}
        variables={campsiteListingVariables}
      >
        {({
          client, data, loading, networkStatus,
        }) => {
          const campsiteListing = getCampsiteListing(cloneDeep(data));
          const allResults = sortByFeatured(campsiteListing.data);
          const results = filterEventType(allResults, eventType);

          return (
            <Query
              notifyOnNetworkStatusChange
              query={GET_PRICES}
              variables={campsitePricesVariables}
              skip={listingVariables.skip}
              fetchPolicy={getFetchPolicyWithExpiry(pricesCacheKey, {
                defaultPolicy: FetchPolicy.CACHE_FIRST,
                expiry: 1000 * 60 * 5, // 5 minutes
                expiryPolicy: FetchPolicy.NETWORK_ONLY,
              })}
            >
              {(priceResponse) => {
                const searchAndPricesAreComplete = updateAndCheckSearchAndPricesComplete(
                  networkStatus,
                  priceResponse.networkStatus,
                  client,
                  results,
                  priceResponse.data,
                  searchCacheKey,
                );

                if (priceResponse.networkStatus === 2 || priceResponse.networkStatus === 1) {
                  addTiming(timingTypes.MAP_ZOOM, 'Results Prices Loading');
                }

                const prices = getPrices(cloneDeep(priceResponse.data));
                const mergedListingAndPricesResults = mergeListingAndPrices(
                  results,
                  [...prices.data],
                  eventType,
                  isOverseas,
                );

                // Here we take our results from the latest network request and update the store.
                // we can do this in the render method, as we are not updating any state,
                // purely making leverage of a single ref.
                updatePaginationResultsStore(mergedListingAndPricesResults, networkStatus);
                // We pull what ever is in the store here
                const resultsUsingPaginationStore = getPaginationResultsFromStore();

                // Only show loading on when we have no results
                if ((
                  networkStatus === 1 && loading && resultsUsingPaginationStore.length === 0)
                  || !eventType
                ) {
                  if (divWrapperRef && divWrapperRef.current) {
                    divWrapperRef.current.parentNode.scrollTo(0, 0);
                  }
                  return <LoadingSpinner dictionary={dictionaryItem('SearchResults', 'Campsite', 'Fetching')} marginTop />;
                }

                // TEMP Performance Hooks, TODO can be safely removed at any time
                if (priceResponse.networkStatus === 2 || priceResponse.networkStatus === 1) {
                  addTiming(timingTypes.MAP_ZOOM, 'Results Prices Loading');
                }
                const resultsFinallyToRender = sortCampsites(
                  resultsUsingPaginationStore, sortType, queryTypes,
                );

                // TODO - This needs a refactor, it causes an error in the console
                // regarding updating a component while rendering another
                // There's not a quick fix to this as it needs a somewhat
                // substantial refactor
                props.setCount(resultsFinallyToRender.length);

                // No Results
                if (!loading && !resultsFinallyToRender.length) {
                  return (
                    <>
                      {showOverseasSiteNightVoucher &&
                        <SiteNightVoucher
                          toggleBasket={toggleBasket}
                          onProductTypeMismatch={onProductTypeMismatch}
                        />
                      }
                      <SearchResultsEmpty
                        onSubmit={props.onQueryChange}
                        query={{
                          features: listingVariables.features,
                          types: listingVariables.types,
                          // TODO: Use campsite/map centre
                          sw_lat: listingVariables.bottomRightLat,
                          sw_lng: listingVariables.bottomRightLon,
                        }}
                      />
                    </>
                  );
                }

                const resultsHasActivePin = resultsFinallyToRender.find(
                  (result) => result.id.toString() === activePin?.toString(),
                );

                const showSpecialListingItem =
                  !isMobile &&
                  activePin &&
                  resultsFinallyToRender.length > 0 &&
                  !resultsHasActivePin;

                // Yes Results
                return (
                  <>
                    <CheckToScrollToActive
                      activePin={activePin}
                      searchCacheKey={searchCacheKey}
                      complete={searchAndPricesAreComplete}
                      page={page}
                      callScrollToActive={() => {
                        scrollToActive(
                          resultsListRef,
                          isMobile,
                          activePin,
                          showSpecialListingItem,
                        );
                      }}
                    />
                    {showOverseasSiteNightVoucher &&
                      <SiteNightVoucher
                        toggleBasket={toggleBasket}
                        onProductTypeMismatch={onProductTypeMismatch}
                      />
                    }

                    {showSpecialListingItem && (
                      <SpecialListingItem
                        campsiteId={activePin}
                        activePin={activePin}
                        eventType={eventType}
                        isOverseas={isOverseas}
                        isTours={isTours}
                        start={start}
                        end={end}
                        isUkItinerary={!!suggestedUkItineraryId}
                        onLoadImage={onLoadImage}
                        onResultClick={props.onResultClick}
                        loadingCampsites={loading}
                        loadingPrices={priceResponse.loading}
                      />
                    )}

                    {resultsFinallyToRender.map((item, index) => {
                      const defaultEvent = getDefaultPlaceEvent(
                        { events: item.placeEvent },
                        eventType,
                        isOverseas,
                      );
                      return (
                        <ListingItem
                          {...item}
                          activePin={activePin}
                          eventType={eventType}
                          index={index}
                          isOpen={checkOpen(defaultEvent?.openDates, start, end)}
                          isTours={isTours}
                          isUkItinerary={!!suggestedUkItineraryId}
                          key={item?.id}
                          loadingCampsites={loading}
                          loadingPrices={priceResponse.loading}
                          memberPrice={item.memberPrice ?? 0}
                          onLoadImage={onLoadImage}
                          onResultClick={props.onResultClick}
                        />
                      );
                    })}

                    <ViewMoreResults
                      total={priceResponse?.data?.prices?.count ?? 0}
                      count={resultsFinallyToRender.length}
                      viewMoreLoading={loading}
                      nextPage={nextPage}
                      isOverseas={isOverseas}
                      client={client}
                    />
                  </>
                );
              }}
            </Query>
          );
        }}
      </Query>
    </SearchResultsWrapper>
  );
}

CampsiteListAndPriceQuery.propTypes = {
  activePin: PropTypes.string,
  setCount: PropTypes.func.isRequired,
  types: PropTypes.arrayOf(PropTypes.number).isRequired,
  onListAndPriceUpdate: PropTypes.func,
  onQueryChange: PropTypes.func.isRequired,
  onResultClick: PropTypes.func.isRequired,
  topLeftLat: PropTypes.number.isRequired,
  topLeftLon: PropTypes.number.isRequired,
  bottomRightLat: PropTypes.number.isRequired,
  bottomRightLon: PropTypes.number.isRequired,
  features: PropTypes.arrayOf(PropTypes.string).isRequired,
  toggleBasket: PropTypes.func.isRequired,
  start: PropTypes.string,
  end: PropTypes.string,
  sortType: PropTypes.number.isRequired,
  onProductTypeMismatch: PropTypes.func,
  isOverseas: PropTypes.bool,
  eventType: PropTypes.number,
  isTours: PropTypes.bool,
  suggestedUkItineraryId: PropTypes.string,
  searchBy: PropTypes.string,
  page: PropTypes.number,
  setTotal: PropTypes.func,
  setLoading: PropTypes.func,
  isMobile: PropTypes.bool,
  resultsListRef: PropTypes.shape({ component: PropTypes.instanceOf(React.Component) }),
  nextPage: PropTypes.func,
};

CampsiteListAndPriceQuery.defaultProps = {
  activePin: null,
  onListAndPriceUpdate: () => { },
  start: null,
  end: null,
  onProductTypeMismatch: null,
  isOverseas: false,
  isTours: false,
  eventType: PLACE_EVENT_TYPES.TOURING.id,
  suggestedUkItineraryId: '',
  searchBy: '',
  page: 0,
  setTotal: () => { },
  setLoading: () => { },
  isMobile: false,
  resultsListRef: () => { },
  nextPage: () => { },
};

export default memo(CampsiteListAndPriceQuery, isEqual);
