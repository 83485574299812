// Eslint disable needed so we can use variable names such as ne_lat
/* eslint-disable camelcase */

import React, { PureComponent, memo } from 'react';
import { withRouter } from 'next/router';
import PropTypes from 'prop-types';
import { graphql, compose, withApollo } from 'react-apollo';
import { v4 as uuid } from 'uuid';
import { isEqual } from 'lodash';
import Querystring from 'querystring';

import withGoogle from '../../hocs/withGoogle';

import updateRouterQuery, { parseQuery } from '../../lib/updateRouterQuery';
import { parseTypes } from '../../lib/campsiteTypes';
import {
  parseBounds,
  shouldBoundsUpdate,
  hasMapMoved,
} from '../../lib/query';
import { ids, keys } from '../../config/campsiteTypes';

import ErrorMessage from '../ErrorMessage';
import Map, { MapOffline } from '../Map';
import PermissionsModal from './PermissionsModal';

import GET_MAP from './graphql/getMap';
import GET_SITES_CONFIG from '../../config/graphql/getSitesConfig';
import GET_SUGGESTED_UK_ITINERARIES from '../../config/graphql/getSuggestedUkItineraries';
import GET_POPUP from '../PopUp/graphql/getPopUp';
import IbePropTypes from '../../IbePropTypes';
import routes from '../../constants/routes';
import {
  addTiming,
  hasMetric,
  startTiming,
  types,
} from '../../lib/timings';
import { GOOGLE_API_MAP_ZOOM_DEFAULT } from '../../config/apis';
import { LOCATION_ICON_PATH, MY_LOCATION_SEARCH_KEY } from '../../constants/search';

import {
  USER_LOCATION_ACCEPTANCE,
} from '../../lib/constants';
import {
  BROWSER_PERMISSIONS,
  GEOLOCATION_FAILURE_CODES,
  USER_LOCATION_PERMISSIONS,
} from '../../lib/location';
import {
  adjustBoundsToFitMap,
  checkUserLocationPermissions,
  geolocationFailure,
  getActualLocationPermissions,
  getBoundsFromCenterAndZoom,
  getLastKnownLocation,
  getUserLocationAsync,
} from './helpers';
import { MARKER_Z_INDEX } from './constants';
import createMyLocationMarker from '../../lib/createMyLocationMarker';
import { isMobileWidth } from '../Layouts/SearchWrapper';

import { MIN_ZOOM } from '../Map/Map';

const DEFAULT_UK_ITINERARY_ZOOM = 7;
const DEFAULT_POI_ZOOM = 14;
const USER_LOCATION_ZOOM = 10;

function parsePositionProps(campaign = null, userPosition, isMobile) {
  if (!campaign) return {};
  const defaultCenter = (userPosition?.lat && userPosition?.lng) ? (
    userPosition
  ) : { lat: campaign.lat, lng: campaign.lon };
  const defaultZoom = userPosition?.zoomLevel ?? campaign.zoomLevel ?? MIN_ZOOM;
  return {
    defaultCenter,
    defaultZoom,
  };
}

function getQueryFromProps(props) {
  return {
    activePin: props.activePin,
    campaignCode: props.campaignCode,
    campsiteId: props.campsiteId,
    ne_lat: props.ne_lat,
    ne_lng: props.ne_lng,
    poi_lat: props.poi_lat,
    poi_lng: props.poi_lng,
    location: props.searchLocation,
    searchBy: props.searchBy,
    siteCode: props.siteCode,
    sw_lat: props.sw_lat,
    sw_lng: props.sw_lng,
    zoomLevel: props.zoomLevel,
    types: props.query?.types,
    isOverseas: props.query?.isOverseas,
    isNearby: props.query?.isNearby,
    eventType: typeof props.query?.eventType === 'string'
      ? parseInt(props.query?.eventType, 10)
      : props.query?.eventType,
    isTours: props.query?.isTours,
    tourCode: props.tourCode,
    ukItinerary: props.ukItinerary,
  };
}

// eslint-disable-next-line no-global-assign
if (typeof Element === 'undefined') {
  global.Element = () => { };
}

class CampsiteMap extends PureComponent {
  static propTypes = {
    activePin: PropTypes.string,
    className: PropTypes.string,
    client: PropTypes.shape(IbePropTypes.client).isRequired,
    campsiteId: PropTypes.string,
    data: PropTypes.shape({
      currentCampsite: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      }).isRequired,
    }).isRequired,
    goToTop: PropTypes.func,
    isOverseas: PropTypes.bool,
    isMobile: PropTypes.bool,
    onZoomChanged: PropTypes.func,
    onQueryChange: PropTypes.func,
    render: PropTypes.func,
    scriptReady: PropTypes.bool,
    zoom: PropTypes.number,
    zoomLevel: PropTypes.number,
    isVisible: PropTypes.bool.isRequired,
    campaign: PropTypes.shape({}),
    method: PropTypes.string,
    siteCode: PropTypes.string,
    showResults: PropTypes.bool,
    onQueInitialMapSearch: PropTypes.func,
    error: PropTypes.shape({}),
    query: PropTypes.shape({
      types: PropTypes.arrayOf(PropTypes.number),
      isFromWidget: PropTypes.string,
    }),
    onClickFilterPoi: PropTypes.func,
    sitesConfig: PropTypes.shape(IbePropTypes.sitesConfig).isRequired,
    searchWrapperRef: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
    ]),
    suggestedUkItineraries: PropTypes.arrayOf(PropTypes.shape(IbePropTypes.ukItinerary)),
    userLat: PropTypes.number,
    userLng: PropTypes.number,
    userZoom: PropTypes.number,
    tours: PropTypes.arrayOf(PropTypes.shape(IbePropTypes.tour)),
    isMobileMapOpen: PropTypes.bool,
    classInstanceRefFunc: PropTypes.func,
    campsiteMapRef: PropTypes.func,
    locationPermission: PropTypes.string,
    location: PropTypes.shape(IbePropTypes.coordinates),
    router: PropTypes.shape(IbePropTypes.router).isRequired,
  };

  static defaultProps = {
    activePin: undefined,
    className: '',
    campaign: null,
    error: null,
    campsiteId: '',
    isMobile: false,
    siteCode: '',
    method: 'push',
    scriptReady: false,
    zoom: null,
    zoomLevel: null,
    onClickFilterPoi: () => { },
    suggestedUkItineraries: [],
    userLat: null,
    userLng: null,
    userZoom: null,
    tours: [],
    isMobileMapOpen: false,
    classInstanceRefFunc: () => {},
    campsiteMapRef: () => {},
    locationPermission: USER_LOCATION_PERMISSIONS.NOT_KNOWN,
    location: {
      lat: null,
      lng: null,
    },
    goToTop: () => {},
    onZoomChanged: () => {},
    onQueryChange: () => {},
    isOverseas: false,
    render: () => {},
    showResults: false,
    onQueInitialMapSearch: () => {},
    searchWrapperRef: {},
    query: {
      types: [],
    },
  };

  state = {
    isOnline: navigator.onLine,
    center: null,
    locationMarker: null,
    userLocationIsVisible: false,
    locationPermission: USER_LOCATION_PERMISSIONS.NOT_KNOWN,
    userLocation: {
      lat: null,
      lng: null,
    },
    showGeolocationModal: false,
    // required for map to update after idle to render clusters consistently
    forceMapMarkerRenderUpdateKey: uuid(),
    // state managed by getDerivedStateFromProps
    query: {},
    parsedTypes: {},
    positionProps: {},
  }

  fromMapAction = false;

  hasIdled = false;

  map = null;

  resize = false;

  width = null;

  height = null;

  pinClick = false;

  panningToPOI = false;

  centerMarker = null;

  constructor(props) {
    super(props);

    // class instance ref is passed to allow for imperative handler calling
    // rather than analyze change in props to call internal handlers, we've
    // stop doing this for detecting searchBy, as we need to persist this
    // and not use it as a trap to trigger a map update and then delete the trap.
    props.classInstanceRefFunc(this);

    // Used to track what number location success response we're on
    // we only move users on the first
    this.userLocationUpdateCount = React.createRef();
    this.userLocationUpdateCount.current = 0;

    // Tracks the number of times the user has moved the map
    // for the current search
    this.mapMoveCountSinceSearchRef = React.createRef();
    this.mapMoveCountSinceSearchRef.current = 0;

    // Tracks whether the map has been made visible for the first time
    // 'mounting' isn't really a concept since moving to a portal
    this.hasMountedRef = React.createRef();

    this.mapConfig = props.sitesConfig.mapConfiguration ?? {};
  }

  componentDidMount = async () => {
    const initialPermissions = await checkUserLocationPermissions();

    // Track any storage updates from other components to see if
    // we update permission storage
    window.addEventListener('storage', this.handleStorageUpdate);

    // Track browser location permission changes, this runs when the
    // user updates their settings
    if (navigator?.permissions?.query) {
      navigator.permissions.query({
        name: 'geolocation',
      }).then((permissionStatus) => {
        // eslint-disable-next-line no-param-reassign
        permissionStatus.onchange = () => {
          this.handlePermissionsChange(permissionStatus);
        };
      });
    }

    // Attempt to place location marker if we have a last
    // known location
    const lastKnownLocation = getLastKnownLocation();
    if (lastKnownLocation) {
      this.placeMyLocationMarker(lastKnownLocation, false);
    }

    this.setState({
      locationPermission: initialPermissions,
    });
  }

  async componentDidUpdate(prevProps) {
    const query = getQueryFromProps(this.props);
    const prevQuery = getQueryFromProps(prevProps);

    const hasQueryChanged = !isEqual(query, prevQuery);

    const tourCodeChanged = prevQuery.tourCode !== query.tourCode;
    const isTourItinerary = !!query.tourCode && tourCodeChanged;
    if (isTourItinerary) {
      this.handleTourItinerary(query.tourCode);
    }

    // Handle uk itinerary change
    if (query.ukItinerary && (prevQuery.ukItinerary !== query.ukItinerary)) {
      this.handleUkItinerary(query);
    }

    // Update the marker location when the location prop updates
    // Should update every X seconds (defined in config)
    // setInterval in sites.js
    if (!isEqual(prevProps.location, this.props.location)) {
      this.handleMyLocation(false, this.props.location);
    }

    // Get the current permission from the browser
    const locationPermission = await checkUserLocationPermissions();

    if (locationPermission === USER_LOCATION_PERMISSIONS.ACCEPTED) {
      if (!this.locationMarker) {
        try {
          const position = await getUserLocationAsync();
          this.handleMyLocation(false, position);
        } catch (error) {
          return;
        }
      }
      this.checkMyLocationVisible();
    }

    const mapContainer = document.querySelector('[data-component-element="true"]');
    if (mapContainer) {
      const isMobileMapClosed = this.props.isMobile && !this.props.isMobileMapOpen;
      if (isMobileMapClosed && !mapContainer.style?.left) {
        mapContainer.style.left = '0';
        mapContainer.style.zIndex = -1;
      } else if (mapContainer.style.left && !isMobileMapClosed) {
        mapContainer.style.left = '';
        mapContainer.style.zIndex = 'auto';
      }
    }

    if (query.poi_lat && query.poi_lng && hasQueryChanged) {
      this.handlePointOfInterest({
        ...query,
        zoomLevel: DEFAULT_POI_ZOOM,
      });
      return;
    }

    if (!query.ne_lat || !query.sw_lat || !query.ne_lng || !query.sw_lng) {
      await this.updateQueryBounds();
    }

    if (!this.props.campsiteMapRef?.current) {
      this.props.classInstanceRefFunc(this);
    }

    // Ensures we're always at the correct place in the map when
    // toggling back and forth
    if (!isEqual(this.props.query, prevProps.query)) {
      this.handleFitToBounds(this.props.query);
      this.updateBoundsIfChanged();
    }

    // When the map is first toggled on mobile, run a search
    const hasMobileMapJustOpened = !prevProps.isMobileMapOpen && this.props.isMobileMapOpen;
    if (!this.hasMountedRef.current && hasMobileMapJustOpened) {
      this.handleSearch();
      this.hasMountedRef.current = true;
      const { isFromWidget, ...restQuery } = this.props.query;
      if (this.props.query.isFromWidget === 'true') {
        // Zoom in by 1 step
        this.map.setZoom(this.map.getZoom() + 1);
        // and remove this query param from the qs as we only need to do this once
        updateRouterQuery(routes.sites, restQuery);
        await this.props.onQueryChange(restQuery);
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('storage', this.handleStorageUpdate);
    navigator.geolocation.clearWatch(this.locationWatcher);
  }

  /*
    Lifecycle method to reduce renders in the child (Map.jsx).
    The contents of this method have been moved up from render so
    we keep references to objects in state, which then stops the
    memoized child rendering more than it should
  */
  static getDerivedStateFromProps(props, state) {
    let newState = {};

    const {
      sitesConfig,
      isOverseas,
      isMobile,
      campaign,
      userLat,
      userLng,
      userZoom,
    } = props;

    const defaultCampaignCodeOverseas = 'default-os';
    const defaultCampaignCodeUk = 'default-uk';
    const { codes } = sitesConfig.campaignCodes;
    const defaultCampaignOverseas
      = codes.find(({ campaignCode }) => campaignCode === defaultCampaignCodeOverseas);
    const defaultCampaignUk
      = codes.find(({ campaignCode }) => campaignCode === defaultCampaignCodeUk);

    const positionProps = parsePositionProps(
      campaign ?? (isOverseas ? defaultCampaignOverseas : defaultCampaignUk),
      { lat: userLat, lng: userLng, zoomLevel: userZoom },
      isMobile,
    );

    const query = {
      ...getQueryFromProps(props),
    };

    const parsedTypes = parseTypes(query.types);

    // Check if the query has actually changed
    if (!isEqual(query, state.query)) {
      newState = {
        ...newState,
        query,
      };
    }

    if (!isEqual(parsedTypes, state.parsedTypes)) {
      newState = {
        ...newState,
        parsedTypes,
      };
    }

    // Same for map positioning
    if (!isEqual(positionProps, state.positionProps)) {
      newState = {
        ...newState,
        positionProps,
      };
    }

    // If none of the above items have changed, do nothing
    if (Object.keys(newState)?.length === 0) return null;

    // Otherwise return the updated state
    // (returning from this method pushes the return value to state)
    return newState;
  }

  locationUpdated = (userLocation) => {
    this.userLocationUpdateCount.current += 1;

    if (this.userLocationUpdateCount === 1) {
      this.moveUser(userLocation);
    }

    this.handleMyLocation(false, userLocation);

    this.setState({
      userLocation,
    });
  }

  requestPermission = async (moveUser = false) => {
    this.setGeoLocationModalState(false);

    try {
      const location = await getUserLocationAsync();
      this.handleMyLocation(false, location);

      if (moveUser) {
        this.moveUser(location);
      }

      return location;
    } catch (error) {
      return null;
    }
  }

  setGeoLocationModalState = (state) => {
    this.setState({
      showGeolocationModal: state,
    });
  }

  handleStorageUpdate = async () => {
    const newValue = await checkUserLocationPermissions();
    if (newValue === USER_LOCATION_PERMISSIONS.ACCEPTED) {
      this.handleMyLocation(false);
    }

    this.setState({
      locationPermission: newValue,
    });
  }

  // Triggers when the user changes their browser location
  // permission settings
  handlePermissionsChange = (permissionStatus) => {
    switch (permissionStatus.state) {
      case BROWSER_PERMISSIONS.GRANTED: {
        this.handleMyLocation(false);
        break;
      }
      default: {
        // Remove location pin
        if (this.state.locationMarker) {
          this.state.locationMarker.setMap(null);
        }
        this.setState({
          locationMarker: null,
        });
        window.navigator.geolocation.clearWatch(this.locationWatcher);
        this.locationWatcher = null;
        // Used to update sessionStorage with the new status
        geolocationFailure(GEOLOCATION_FAILURE_CODES.DENIED);
      }
    }
  }

  handleCampsiteSearch = ({
    lat,
    lng,
    zoomLevel,
  }, isSearch) => {
    if (!lat || !lng) return;
    const { query } = this.props.router;
    // Pan map to campsite marker
    setTimeout(async () => {
      if (!this.map) return;
      const mapContainer = this.map.getDiv();
      const mapDimensions = mapContainer?.getBoundingClientRect();
      if (!mapDimensions) return;
      const [ne, sw] = getBoundsFromCenterAndZoom(
        { lat, lng },
        isSearch ? zoomLevel : this.map?.getZoom(),
        mapDimensions,
      );
      const bounds = new window.google.maps.LatLngBounds(
        new window.google.maps.LatLng(sw.lat, sw.lng),
        new window.google.maps.LatLng(ne.lat, ne.lng),
      );
      await this.map.fitBounds(bounds);
      const parsedBounds = parseBounds(bounds);
      const updatedQuery = {
        ...query,
        ...parsedBounds,
      };
      await updateRouterQuery(routes.sites, updatedQuery, this.props.method);
      await this.props.onQueryChange(updatedQuery);
    }, 0);

    // Reset user map move count
    this.mapMoveCountSinceSearchRef.current = 0;
  }

  handleLocationSearch = (query) => {
    // Place location pin if we already have permission
    this.placeLocationMarkerAtLastKnownLocation();
    this.addMapInfo(query);
    this.handleFitToBounds(query);

    // Reset user map move count
    this.mapMoveCountSinceSearchRef.current = 0;
  }

  addMapInfo = (query) => {
    const {
      placeId,
      locationType,
      location,
      centerLat,
      centerLng,
    } = query;

    // clear previous styling
    this.clearFeatureLayers();

    // Place user location if we can
    const lastKnownLocation = getLastKnownLocation();

    if (this.centerMarker) {
      this.centerMarker.setMap(null);
      this.centerMarker = null;
    }

    const capitalizedFeatureLayer = locationType ? locationType.toUpperCase() : null;

    const isValidLocationType = locationType
      && this.mapConfig.regionTypes.includes(capitalizedFeatureLayer);

    const canHighlight = placeId && isValidLocationType;

    // Check if the search center is the same as our location
    const isSameCenterLat = parseFloat(centerLat) === lastKnownLocation?.lat;
    const isSameCenterLng = parseFloat(centerLng) === lastKnownLocation?.lng;
    const isCenterMyLocation = isSameCenterLat && isSameCenterLng;

    const isMyLocationSearch = (location === MY_LOCATION_SEARCH_KEY) || isCenterMyLocation;

    // Colour region and place center marker (if applicable)
    // Don't show for highlighted regions or my location searches
    if (!isMyLocationSearch && !canHighlight) {
      this.placeCenterMarker({
        lat: centerLat,
        lng: centerLng,
      });
    }

    if (canHighlight) {
      this.colorFeatureLayer(placeId, capitalizedFeatureLayer);
    }
  };

  placeCenterMarker = (coords) => {
    if (!window) return;
    if (!coords) return;
    if (!coords?.lat || !coords?.lng) return;

    this.centerMarker = new window.google.maps.Marker({
      position: {
        lat: parseFloat(coords.lat),
        lng: parseFloat(coords.lng),
      },
      zIndex: MARKER_Z_INDEX.SEARCH_LOCATION,
      optimized: false,
      icon: {
        anchor: new window.google.maps.Point(13, 35),
        path: LOCATION_ICON_PATH,
        fillColor: this.mapConfig.regionCentrePinColour,
        fillOpacity: 1,
        strokeColor: this.mapConfig.regionCentrePinColour,
      },
    });

    this.centerMarker.setMap(this.map);
  }

  colorFeatureLayer = (placeId, featureLayerName) => {
    const featureLayer = this.map.getFeatureLayer(featureLayerName);
    if (!featureLayer) return;
    const featureStyleOptions = {
      strokeColor: this.mapConfig.regionBorderColour,
      strokeOpacity: 0.8,
      strokeWeight: 3.0,
    };

    const nonActiveFeatureLayerStyleOptions = {
      fillColor: '#000',
      fillOpacity: 0.1,
    };

    // Apply the style to a single boundary.
    featureLayer.style = (options) => {
      if (options.feature.placeId === placeId) {
        return featureStyleOptions;
      }
      return nonActiveFeatureLayerStyleOptions;
    };
  };

  clearFeatureLayers = () => {
    if (!this.mapConfig.regionTypes?.length) return;
    this.mapConfig.regionTypes.forEach((layer) => {
      const featureLayer = this.map.getFeatureLayer(layer);
      if (!featureLayer) return;
      // Clear all styles from the boundary
      featureLayer.style = null;
    });
  };

  handleFitToBounds = ({
    // eslint-disable-next-line camelcase
    ne_lat, ne_lng, sw_lat, sw_lng,
  }) => {
    // eslint-disable-next-line camelcase
    if (ne_lat && sw_lng && ne_lat && sw_lat && this.map) {
      // Get new map bounds
      const currentBounds = this.map.getBounds();
      const bounds = new window.google.maps.LatLngBounds();
      bounds.extend({ lat: parseFloat(ne_lat), lng: parseFloat(sw_lng) });
      bounds.extend({ lat: parseFloat(sw_lat), lng: parseFloat(ne_lng) });

      if (currentBounds && !currentBounds.equals(bounds)) {
        this.map.fitBounds(bounds, 0);
        // Pan to new center, prevents stale data being shown on map
        const mapCenter = this.map.getCenter();
        this.map.panTo({ lat: mapCenter.lat(), lng: mapCenter.lng() });
      }
    }
  }

  handleUkItinerary = (query) => {
    const { suggestedUkItineraries } = this.props;
    const activeUkItinerary = suggestedUkItineraries?.find(
      (item) => item.id?.toString() === query.ukItinerary,
    );
    if (!activeUkItinerary) {
      return;
    }
    const { zoomLevel, lat, lon } = activeUkItinerary.location ?? {};
    const zoom = zoomLevel || DEFAULT_UK_ITINERARY_ZOOM;
    this.props.onZoomChanged(zoom);

    if (lat && lon) {
      // Pan map to itineraries' center
      this.map.panTo({ lat, lng: lon });
    }
  }

  handleTourItinerary = (tourCode) => {
    const activeTour = this.props.tours.find((tour) => tour.id?.toString === tourCode);
    if (activeTour) {
      const bounds = new window.google.maps.LatLngBounds();
      (activeTour.itinerary ?? []).forEach((itineraryItem) => {
        bounds.extend({
          lat: itineraryItem.location?.lat,
          lng: itineraryItem.location?.lon,
        });
      });
      this.map.fitBounds(bounds, 50);
    }
  }

  handlePointOfInterest = async ({ poi_lat: lat, poi_lng: lng, zoomLevel }) => {
    this.panningToPOI = true;

    this.handleCampsiteSearch({ lat, lng, zoomLevel }, true);

    if (this.props.isMobile) {
      this.forceZoom(zoomLevel);
    }

    this.panningToPOI = false;
  }

  forceZoom = (zoomLevel) => {
    const mapZoom = this.map?.getZoom();

    // force google map library to change zoom levels on mobile
    // it fails to recognize zoom level updates if first rendered with a zoom level set
    // e.g. view on map on mobile. changing to something else and then
    // setting back the set zoom level works around it
    if (zoomLevel !== mapZoom) {
      this.props.onZoomChanged(GOOGLE_API_MAP_ZOOM_DEFAULT);

      // add time buffer to ensure the 2 calls don't interfere with each other
      setTimeout(() => {
        this.props.onZoomChanged(zoomLevel);
      }, 500);
    }
  }

  checkConnectivity = () => {
    if (this.state.isOnline === navigator.onLine) return;
    this.setState({ isOnline: navigator.onLine });
  }

  getMapCenter = () => {
    const lat = this.map?.getCenter().lat();
    const lng = this.map?.getCenter().lng();
    const zoom = this.map?.getZoom();
    return { lat, lng, zoom };
  }

  /**
   * We check here wether to close info window.
   * @param { event } maps mouseEvent
   */
  handleMapClick = ({ domEvent }) => {
    const infoWindowElement = document.querySelector('#InfoWindow');
    let userHasClickedOnMoreDetails = false;
    if (infoWindowElement) {
      const {
        bottom,
        left,
        right,
        top,
      } = infoWindowElement.getBoundingClientRect();
      if (domEvent.x > left && domEvent.y < right && domEvent.y > top && domEvent.y < bottom) {
        userHasClickedOnMoreDetails = true;
      }
    }

    window.setTimeout(async () => {
      if (this.props.activePin && !userHasClickedOnMoreDetails) {
        const { hasChanged } = await updateRouterQuery(routes.sites, { activePin: null });
        if (hasChanged) {
          await this.props.onQueryChange({ activePin: null });
        }
      }
    }, 0);
  }

  placeLocationMarkerAtLastKnownLocation = () => {
    if (!this.state.locationMarker) {
      const lastKnownLocation = getLastKnownLocation();
      this.placeMyLocationMarker(lastKnownLocation, false);
    }
  }

  handleMounted = async (map) => {
    if (!map || !this.props.isVisible) return;
    // TEMP Performance Hooks, TODO can be safely removed at any time
    addTiming(types.MAP, 'Initialised');

    this.map = map;

    const querystringObject = Querystring.parse(this.props.router.asPath);

    const query = parseQuery(querystringObject);

    this.updateBoundsIfChanged();

    if (!query.ne_lat) return;

    // Else pan and fit map to location bounds
    const bounds = new window.google.maps.LatLngBounds();
    bounds.extend({ lat: query.ne_lat, lng: query.sw_lng });
    bounds.extend({ lat: query.sw_lat, lng: query.ne_lng });

    const isMobile = isMobileWidth(window.innerWidth);
    const { isFromWidget } = this.props.query;
    // Normally for location searches we adjust the bounds to fit the screen when we get the
    // location bounds from Google in PlacesTypeahead, but on the widget we don't know
    // the screen size so we have to do it here. Desktop is also fine as the map is visible
    // on load.
    if (isFromWidget === 'true' && isMobile) {
      // If we've come from the widget we adjust the bounds to fit the screen
      const adjustedBounds = adjustBoundsToFitMap(bounds);
      const adjustedQuery = {
        ...this.props.query,
        ...parseBounds(adjustedBounds),
      };
      map.fitBounds(adjustedBounds, 0);
      this.updateQueryBounds();
      updateRouterQuery(routes.sites, {
        ...this.props.query,
        ...adjustedQuery,
      });
    } else {
      map.fitBounds(bounds, 0);
      // Pan to new center, prevents stale data being shown on map
      const boundsCenter = bounds.getCenter();
      map.panTo({ lat: boundsCenter.lat(), lng: boundsCenter.lng() });
    }

    // Section to sync the results list and map view when making a 'my location' search
    // Handled differently due to how we need to generate bounds around a center point
    const isMyLocationSearch = query.location === MY_LOCATION_SEARCH_KEY;
    const myLocationZoom = this.props.sitesConfig?.myLocationSearchZoom ?? 10;
    const isZoomCorrect = myLocationZoom === query.zoomLevel;
    const isNearbySearch = !!query.isNearby;
    if (isMyLocationSearch && !isZoomCorrect && !isNearbySearch) {
      // Run this after we are done with the standard movement - this will not
      // work unless it's run asynchronously. Only affects 'my location' searches
      setTimeout(() => {
        this.props.onZoomChanged(myLocationZoom);
      }, 100);
    }

    this.handleSearch();

    // Place location pin if we already have permission
    this.placeLocationMarkerAtLastKnownLocation();

    this.addMapInfo(this.props.router.query);
  }

  checkForOpenModals = async () => {
    const { client } = this.props;
    const { data } = await client.query({ query: GET_POPUP });

    return !![...Object.keys(data ?? {})].map((k) => {
      if (typeof data[k] === 'boolean') {
        return data[k];
      }

      return data[k].open;
    }).filter(x => x).length;
  }

  updateQueryBounds = async (showResults) => {
    if (!this.map) return;

    const anyModalsOpen = await this.checkForOpenModals();
    const { isMobile, isMobileMapOpen, query: currentQuery } = this.props;
    if (anyModalsOpen) return;

    // Get the new the map bounds and zoom level
    const bounds = this.map.getBounds();
    const zoomLevel = this.map.getZoom();

    if (!bounds) return;

    const parsedBounds = parseBounds(bounds);
    const isMobileMapClosed = isMobile && !isMobileMapOpen;
    const zoomChanged = Number(currentQuery.zoomLevel) !== Number(zoomLevel);

    // avoid bounds update if map is closed
    if (isMobileMapClosed && currentQuery.ne_lat) {
      return;
    }

    // avoid bounds update due to small ui updates (spinner close and open)
    if (isMobile
      && !shouldBoundsUpdate(currentQuery, parsedBounds)
      && !zoomChanged
      && !(currentQuery.poi_lat || currentQuery.poi_lng)) {
      return;
    }

    if (parsedBounds.ne_lat === parsedBounds.sw_lat) return;

    const { query } = this.props.router;

    const updatedQuery = {
      ...query,
      ...parsedBounds,
      method: null,
      poi_lat: null,
      poi_lng: null,
      searchBy: currentQuery.searchBy || null,
      zoomLevel,
      zoomTo: null,
      showResults: showResults ?? query.showResults,
    };

    await updateRouterQuery(routes.sites, updatedQuery, this.props.method);
    await this.props.onQueryChange(updatedQuery);
  }

  handleGoogleMapIdle = async () => {
    this.checkMyLocationVisible();
    const center = this.getMapCenter();
    if (!this.state.center || !hasMapMoved(center, this.state.center)) {
      // Ensure we keep center state updated with the most recent data
      this.setState({
        center,
      });
      return;
    }
    this.updateBoundsIfChanged();
    // required for map to update after idle to render clusters consistently
    this.setState({
      forceMapMarkerRenderUpdateKey: uuid(),
    });
  }

  handleDragEnd = () => {
    this.updateQueryBounds(true);
    this.handleSearch();

    this.mapMoveCountSinceSearchRef.current += 1;
  }

  handleMarkerClick = async ({ id, name, type }) => {
    startTiming(types.MAP_CAMPSITE_MARKER);
    // Set active pin
    const query = {
      ...getQueryFromProps(this.props),
      activePin: id,
      method: null,
    };

    // Update campsite details if applicable, we also don't want POI
    // marker clicks to set their id into the campsite id, thus opening
    // site card 2
    if (this.props.campsiteId && type !== ids[keys.POINT_OF_INTEREST]) {
      query.campsiteId = id;
      query.campsiteName = name;
      query.siteCode = this.props.campsiteId === id ?
        this.props.siteCode :
        null;
    }

    // Handle active pin and campsite details
    await updateRouterQuery(routes.sites, query);
    await this.props.onQueryChange(query);
  }

  handleMoreDetailsClick = async () => {
    const query = {
      ...getQueryFromProps(this.props),
      campsiteId: this.props.data.currentCampsite.id,
      campsiteName: this.props.data.currentCampsite.name,
      method: null,
    };

    await updateRouterQuery(routes.sites, query, this.props.method);
    await this.props.onQueryChange(query);
  }

  handleFiltersChange = async (typesId, active) => {
    const { query } = this.props;
    const activeFilters = { types: parseTypes(query.types) };
    if (active) {
      activeFilters.types.push(typesId);
    } else {
      activeFilters.types = activeFilters.types.filter(t => t !== typesId);
    }

    await updateRouterQuery(routes.sites, { ...activeFilters });
    this.props.onQueryChange({ ...query, ...activeFilters });
  }

  handleTilesLoaded = () => {
    // TEMP Performance Hooks, TODO can be safely removed at any time
    addTiming(types.MAP, 'Complete', true);

    const isResultsComplete = hasMetric(types.MAP_ZOOM, 'Results Loaded');
    const arePricesLoading = hasMetric(types.MAP_ZOOM, 'Results Prices Loading');
    const isPricesComplete = hasMetric(types.MAP_ZOOM, 'Results Prices Loaded');
    addTiming(types.MAP_ZOOM, 'Tiles Loaded');
    const isComplete = isResultsComplete && (arePricesLoading ? isPricesComplete : true);
    if (isComplete) {
      addTiming(types.MAP_ZOOM, 'Complete', isComplete);
    }
  }

  handleGetMapBounds = () => this.map && this.map.getBounds();

  handleSearch = () => {
    if (this.props.showResults) return;
    // This ends up calling submit search in SearchWrapper
    this.props.onQueInitialMapSearch();
  }

  handleZoomChanged = async (newZoomLevel) => {
    if (!newZoomLevel) return;
    // We round as with the new vector map we can get non-integer zooms
    // which we aren't using
    const newZoom = Math.round(newZoomLevel);
    const currentZoom = Math.round(this.props.zoomLevel || this.props.zoom);
    if (newZoom === currentZoom) return;
    await this.props.onZoomChanged(newZoomLevel);

    await this.updateQueryBounds();
    // TEMP Performance Hooks, TODO can be safely removed at any time
    addTiming(types.MAP_ZOOM, 'Changed');

    this.handleSearch();
  }

  geoLocationSuccess = (position, moveUser) => {
    sessionStorage.setItem(USER_LOCATION_ACCEPTANCE, USER_LOCATION_PERMISSIONS.ACCEPTED);
    this.setState({
      locationPermission: USER_LOCATION_PERMISSIONS.ACCEPTED,
    });
    this.placeMyLocationMarker(position, moveUser);
  };

  handleMyLocation = (moveUser = true, location) => {
    this.geoLocationSuccess(location || this.state.userLocation, moveUser);
  };

  placeMyLocationMarker = (position, moveUser) => {
    if (!window || !window.google) return;
    if (!position?.lat || !position?.lng) return;
    // Ensure needed modules are loaded
    if (!google.maps.Size || !google.maps.Point) return;
    if (!this.state.locationMarker) {
      const LOCATION_MARKER_SIZE = 30;
      const icon = createMyLocationMarker({ color: this.mapConfig.myLocationPinColour });
      const marker = new window.google.maps.Marker({
        position,
        zIndex: MARKER_Z_INDEX.USER_LOCATION,
        optimized: false,
        icon: {
          url: icon,
          scaledSize: new google.maps.Size(LOCATION_MARKER_SIZE, LOCATION_MARKER_SIZE, 'px', 'px'),
          anchor: new google.maps.Point(LOCATION_MARKER_SIZE / 2, LOCATION_MARKER_SIZE / 2),
        },
      });
      marker.setMap(this.map);
      this.setState({
        locationMarker: marker,
      });
    } else {
      this.state.locationMarker.setPosition(position);
    }
    this.checkMyLocationVisible();
    if (moveUser) {
      this.map.panTo(position);
      this.map.setZoom(USER_LOCATION_ZOOM);
    }
  }

  // Checks if the user's location is visible on the map
  // This determines the styling of the location button
  checkMyLocationVisible = () => {
    if (!this.state.locationMarker) return;
    const location = this.state.locationMarker.getPosition();
    if (!location) return;
    const lat = location.lat();
    const lng = location.lng();
    if (!this.map || (!lat || !lng)) {
      this.setState({
        userLocationIsVisible: false,
      });
      return;
    }
    const currentBounds = this.map.getBounds();
    if (!currentBounds) return;
    const isVisible = currentBounds.contains(location);
    if (isVisible === this.state.userLocationIsVisible) return;
    this.setState({
      userLocationIsVisible: isVisible,
    });
  }

  onLocationButtonClick = async () => {
    if (
      this.state.locationPermission === USER_LOCATION_PERMISSIONS.DECLINED
    ) {
      this.setState({
        showGeolocationModal: true,
      });
      return;
    }

    // If the user has already accepted permissions, don't bother showing the modal

    const location = await this.requestPermission();

    if (!location) return;

    this.moveUser(location);
  };

  moveUser = (position) => {
    const { lat, lng } = position;
    if (!lat || !lng) return;
    this.map.panTo(position);
    this.map.setZoom(USER_LOCATION_ZOOM);

    this.updateQueryBounds();
    this.handleSearch();
  }

  updateBoundsIfChanged = () => {
    if (!this.map) return;
    const {
      ne_lat,
      ne_lng,
      sw_lat,
      sw_lng,
    } = this.props.router.query;
    const currentBounds = this.map.getBounds();
    const bounds = new window.google.maps.LatLngBounds();
    bounds.extend({ lat: parseFloat(ne_lat), lng: parseFloat(ne_lng) });
    bounds.extend({ lat: parseFloat(sw_lat), lng: parseFloat(sw_lng) });
    // If the bounds get out of sync, update the querystring
    if (currentBounds && !currentBounds.equals(bounds)) {
      this.handleFitToBounds(parseBounds(currentBounds));
      this.updateQueryBounds();
    }
  }

  render() {
    this.checkConnectivity();

    const {
      data,
      error,
      isVisible,
      scriptReady,
      isMobile,
      isMobileMapOpen,
      sitesConfig,
    } = this.props;

    const {
      userLocationIsVisible,
    } = this.state;

    const notReady = [
      this.state.isOnline,
      isVisible,
      data,
      scriptReady,
      sitesConfig,
    ].some(ready => !ready);

    if (!this.state.isOnline) return <MapOffline onClick={this.checkConnectivity} />;

    if (notReady) return null;

    // On error display message
    if (error) {
      return <ErrorMessage />;
    }

    const actualLocationPermission = getActualLocationPermissions(this.state.locationPermission);

    const mapProps = {
      ...this.state.positionProps,
      campsiteTypes: sitesConfig.campsiteTypes,
      onClick: this.handleMapClick,
      onDragEnd: this.handleDragEnd,
      onIdle: this.handleGoogleMapIdle,
      onMounted: this.handleMounted,
      onZoomChanged: this.handleZoomChanged,
      onFiltersChange: this.handleFiltersChange,
      onTilesLoaded: this.handleTilesLoaded,
      zoom: this.props.zoom || this.props.zoomLevel,
      types: this.state.parsedTypes,
      onClickFilterPoi: this.props.onClickFilterPoi,
      onLocationButtonClick: this.onLocationButtonClick,
      locationPermission: actualLocationPermission,
      searchWrapperRef: this.props.searchWrapperRef,
      isLocationActive: !!this.state.locationMarker,
      query: this.state.query,
      userLocationIsVisible,
    };

    const renderFullMap = !isMobile || (isMobile && isMobileMapOpen);

    // GoogleMap wraps the children in sites.js
    return (
      <Map {...mapProps}>
        {this.map && this.props.render && renderFullMap && this.props.render({
          getMapBounds: this.handleGetMapBounds,
          infoWindow: this.props.data.currentCampsite.id,
          isMobile: this.props.isMobile,
          onCampsiteSearch: this.handleCampsiteSearch,
          onMarkerClick: this.handleMarkerClick,
          onMoreDetailsClick: this.handleMoreDetailsClick,
          onQueryChange: this.props.onQueryChange,
          suggestedUkItineraries: this.props.suggestedUkItineraries,
          renderUpdateKey: this.state.forceMapMarkerRenderUpdateKey,
          activePin: this.props.activePin,
        })}
        <PermissionsModal
          active={this.state.showGeolocationModal}
          closeModal={() => this.setGeoLocationModalState(false)}
          geoLocationInfoLink={sitesConfig?.geoLocationPermissionsLink}
        />
      </Map>
    );
  }
}

export default compose(
  graphql(GET_MAP),
  graphql(GET_SITES_CONFIG, {
    props: ({ data }) => ({
      sitesConfig: data.configurationSites,
    }),
  }),
  graphql(GET_SUGGESTED_UK_ITINERARIES, {
    props: ({ data }) => ({
      suggestedUkItineraries: data.UkItineraries?.suggestedUkItineraries ?? [],
    }),
  }),
  withGoogle,
  withApollo,
  withRouter,
  memo,
)(CampsiteMap);
