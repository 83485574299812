import React, {
  Fragment, Component,
} from 'react';
import PropTypes from 'prop-types';
import {
  compose,
  withApollo,
  graphql,
} from 'react-apollo';
import { withRouter } from 'next/router';
import { isEqual } from 'lodash';
import * as Portals from 'react-reverse-portal';

import { initSentry } from '../lib/sentry';
import parseQueryFromRouter from '../lib/helpers/sites';
import updateRouterQuery from '../lib/updateRouterQuery';
import withData from '../lib/withData';
import { dataLayerManager } from '../lib/dataLayer';
import { USER_LOCATION_ACCEPTANCE, virtualPageViewTypes } from '../lib/constants';
import routes from '../constants/routes';

import AppWrapper from '../components/Layouts/AppWrapper';
import SearchWrapper from '../components/Layouts/SearchWrapper';
import CampsiteSearch from '../components/Search/CampsiteSearch';
import DrawerCampsiteAvailability from '../components/Layouts/Drawer/DrawerCampsiteAvailability';
import DrawerTours from '../components/Layouts/Drawer/DrawerTours';
import TourItinerary from '../components/TourItinerary/TourItinerary';
import DrawerSearch from '../components/Layouts/Drawer';
import Quote, { QuoteCampsite } from '../components/Quote';
import ListingResult from '../components/Listing/ListingResult';
import ListingResultTours from '../components/Listing/ListingResultTours';
import Reviews from '../components/Reviews';
import SiteCard from '../components/SiteCard';
import CampsiteMap, { MapMarkers } from '../components/CampsiteMap';
import MembershipDialog from '../components/Dialog/MembershipDialog';
import Filters from '../components/Filters';
import ClusteringPanel from '../components/Search/ClusteringPanel';

import { ReviewsDrawer } from '../components/Layouts/SearchLayout/SearchLayout.style';
import { getMembershipDialogProps } from '../lib/helpers/dialogMembership';
import { DIALOG_MEMBERSHIP_TYPE } from '../config/dialogMembership';
import isLoggedIn from '../lib/isLoggedIn';
import IbePropTypes from '../IbePropTypes';
import GET_QUOTE from '../components/Quote/graphql/getQuote';
import GET_OVERSEAS_TOURS, { getToursOptions } from '../config/graphql/getOverseasTours';
import RESET_SESSION from '../components/Quote/graphql/resetSession';
import { LoadingContainer } from '../components/Layouts/AppWrapper/index.style';
import LoadingSpinner from '../components/ui/Loading/LoadingSpinner';
import { DisplayToRootContainer } from '../components/DisplayToRoot';
import { BASKET_STATES } from '../config/products';
import ViewManager from '../managers/view';
import FetchPolicy from '../constants/FetchPolicy';
import AmendBar from '../components/Amend/AmendBar';
import { FEATURE_FLAGS, getFeatureFlag } from '../config/featureFlags';
import { ids as campsiteTypes, defaultOSTypes, defaultUKTypes } from '../config/campsiteTypes';
import { addTiming, types } from '../lib/timings';
import { defaultSearchByTypeUk, defaultSearchByTypeOverseas } from '../constants/search';
import { BROWSER_PERMISSIONS, GEOLOCATION_FAILURE_CODES, USER_LOCATION_PERMISSIONS } from '../lib/location';
import {
  checkUserLocationPermissions,
  geolocationFailure,
  getActualLocationPermissions,
  getUserLocationAsync,
} from '../components/CampsiteMap/helpers';
import { MIN_ZOOM } from '../components/Map/Map';

import withSitesConfig from '../hocs/withSitesConfig';
import withAppData from '../hocs/withAppData';
import withReversePortal from '../hocs/withReversePortal';

initSentry();

class Sites extends Component {
  static propTypes = {
    env: PropTypes.string,
    router: PropTypes.shape(IbePropTypes.router),
    client: PropTypes.shape(IbePropTypes.client).isRequired,
    resetSession: PropTypes.func.isRequired,
    sitesConfig: PropTypes.shape(IbePropTypes.sitesConfig).isRequired,
    tours: PropTypes.arrayOf(PropTypes.shape(IbePropTypes.tour)),
    quote: PropTypes.shape(PropTypes.quote),
    // From withPortaledMap HOC
    portalNode: IbePropTypes.portalNode,
  }

  static defaultProps = {
    env: 'local',
    router: {
      query: {},
    },
    tours: [],
    quote: undefined,
    portalNode: null,
  }

  constructor(props) {
    super(props);
    this.state = {
      selectedEvent: null,
      searchBy: null,
      dialog: null,
      poiFiltersActive: false,
      additionalCampingPitch: false,
      addingCampingGuests: false,
      poiFilters: [],
      locationPermission: USER_LOCATION_PERMISSIONS.NOT_KNOWN,
      location: {
        lat: null,
        lng: null,
      },
    };
    this.locationInterval = null;
    this.updateSearchBy = this.updateSearchBy.bind(this);
  }

  async componentDidMount() {
    ViewManager.activate();
    this.checkToPushVirtualPageView();
    this.setDefaultQueries();
    this.setDefaultState();

    // TEMP Performance Hooks, TODO can be safely removed at any time
    addTiming(types.IBE, 'UI Loaded');

    // Track browser location permission changes, this runs when the
    // user updates their settings
    if (navigator?.permissions?.query) {
      navigator.permissions.query({
        name: 'geolocation',
      }).then((permissionStatus) => {
        // eslint-disable-next-line no-param-reassign
        permissionStatus.onchange = () => {
          this.handlePermissionsChange(permissionStatus);
        };
      });
    }

    window.addEventListener('storage', this.handleStorageUpdate);

    const locationPermission = await checkUserLocationPermissions();
    if (locationPermission === USER_LOCATION_PERMISSIONS.ACCEPTED) {
      this.requestLocation();
    }

    this.setState({
      locationPermission,
    });
  }

  componentDidUpdate(prevProps) {
    this.checkToPushVirtualPageView();
    this.clearAdvancedGuestStates(prevProps);
  }

  componentWillUnmount() {
    window.removeEventListener('storage', this.handleStorageUpdate);
    clearInterval(this.locationInterval);
  }

  handleStorageUpdate = () => {
    const locationPermission = localStorage.getItem(USER_LOCATION_ACCEPTANCE);
    this.setState({
      locationPermission,
    });
  }

  handlePermissionsChange = async (permissionStatus) => {
    switch (permissionStatus.state) {
      case BROWSER_PERMISSIONS.GRANTED: {
        // Update user location, now that we have permission
        try {
          const location = await getUserLocationAsync();
          this.setState({
            location,
            locationPermission: USER_LOCATION_PERMISSIONS.ACCEPTED,
          });
          // And restart the interval timer to track location changes
          this.startInterval();
        } catch (error) {
          geolocationFailure(GEOLOCATION_FAILURE_CODES.UNAVAILABLE);
        }
        break;
      }
      default: {
        // Clear any references to the interval
        clearInterval(this.locationInterval);
        this.locationInterval = null;
        // Remove location pin
        this.setState({
          location: {
            lat: null,
            lng: null,
          },
          locationPermission: USER_LOCATION_PERMISSIONS.DECLINED,
        });
        // Used to update sessionStorage with the new status
        geolocationFailure(GEOLOCATION_FAILURE_CODES.DENIED);
      }
    }
  }

  requestLocation = async () => {
    this.startInterval();
    try {
      const userLocation = await getUserLocationAsync();
      return userLocation;
    } catch (error) {
      return null;
    }
  }

  startInterval = () => {
    if (!this.locationInterval) {
      const { sitesConfig } = this.props;
      const intervalInSeconds =
        sitesConfig?.mapConfiguration?.locationUpdateIntervalSeconds ?? 180;
      this.locationInterval = setInterval(async () => {
        const hasAccepted = this.state.locationPermission === USER_LOCATION_PERMISSIONS.ACCEPTED;
        if (!hasAccepted) return;
        try {
          const userLocation = await getUserLocationAsync();
          // Check if location has changed before updating state
          if (isEqual(userLocation, this.state.location)) return;
          this.setState({
            location: userLocation,
          });
        } catch (error) {
          geolocationFailure({
            code: GEOLOCATION_FAILURE_CODES.UNAVAILABLE,
          });
        }
      }, intervalInSeconds * 1000);
    }
  }

  locationFailure = (error) => {
    const failureReason = error.code === 1
      ? USER_LOCATION_PERMISSIONS.DECLINED
      : USER_LOCATION_PERMISSIONS.NOT_KNOWN;

    this.setState({
      locationPermission: failureReason,
    });
  }

  locationSuccess = (location) => {
    this.setState({
      locationPermission: USER_LOCATION_PERMISSIONS.ACCEPTED,
      location,
    });
  }

  setDefaultQueries = () => {
    const { router: { query } } = this.props;
    const defaultSearchBy = query?.isOverseas
      ? defaultSearchByTypeOverseas
      : defaultSearchByTypeUk;
    const searchBy = query.searchBy ?? defaultSearchBy;
    if (!getFeatureFlag(FEATURE_FLAGS.UK_SITES) && !query?.isOverseas) {
      updateRouterQuery(routes.sites, {
        isOverseas: 'true',
      });
    }

    // Set the default campsite types to be shown
    const defaultTypes = query?.isOverseas
      ? defaultOSTypes
      : defaultUKTypes;
    updateRouterQuery(routes.sites, {
      types: defaultTypes,
    });

    this.setState({
      searchBy,
    });
  }

  setDefaultState = () => {
    // Set the sortType from the URL in Apollo global state
    const { query } = this.props.router;
    if (query.sortType) {
      this.props.client.writeData({
        data: {
          campsiteSortType: parseInt(query.sortType, 10),
        },
      });
    }
  }

  clearAdvancedGuestStates = (prevProps) => {
    const { router } = this.props;
    const { additionalCampingPitch, addingCampingGuests } = this.state;
    if (!!prevProps.router.query?.siteCode && !router.query.siteCode &&
      (additionalCampingPitch || addingCampingGuests)) {
      this.setState({
        additionalCampingPitch: false,
        addingCampingGuests: false,
      });
    }
  }

  onAdvancedGuestEdit = () => {
    const { router } = this.props;
    const { additionalCampingPitch, addingCampingGuests } = this.state;
    if (!!router.query.siteCode && (!additionalCampingPitch || !addingCampingGuests)) {
      this.setState({
        additionalCampingPitch: true,
        addingCampingGuests: true,
      });
    }
  }

  onToggleAddingCampingGuests = () => {
    this.setState({
      addingCampingGuests: !this.state.addingCampingGuests,
    });
  }

  onToggleAdditionalCampingPitch = () => {
    this.setState({
      additionalCampingPitch: !this.state.additionalCampingPitch,
    });
  }

  onAdditionalCampingPitchBack = () => {
    if (this.state.addingCampingGuests) {
      this.onToggleAddingCampingGuests();
    } else {
      this.onToggleAdditionalCampingPitch();
    }
  }

  onSelectEvent = (selectedEvent) => {
    if (selectedEvent &&
      Number(this.props.router.query.eventType) !== Number(selectedEvent?.eventType)) {
      updateRouterQuery(routes.sites, {
        ...this.props.router.query,
        eventType: selectedEvent?.eventType,
      });
    }
    this.setState({
      selectedEvent,
    });
  }

  onFilterPoiSubmit = (newPoiFilters) => {
    this.setState({
      poiFilters: newPoiFilters?.features,
    });
  }

  onClickFilterPoi = () => {
    this.setState({
      poiFiltersActive: !this.state.poiFiltersActive,
    });
  }

  onBasketToggle = (isOpen, getBasketOpenStateFunc) => {
    // retain the supplied func for subsequent state checks rather than save the
    // state in this instance.
    this.getBasketOpenState = getBasketOpenStateFunc;
    // TEMP Performance Hooks, TODO can be safely removed at any time
    addTiming(types.ADD_TO_BASKET, 'Open Basket Complete', true);
    if (isOpen) {
      dataLayerManager.pushVirtualPageViewEvent(virtualPageViewTypes.BASKET);
    } else {
      this.checkToPushVirtualPageView();
    }
  }

  onCloseDialog = () => {
    this.setState({
      dialog: null,
    });
  }

  // TEMP: for testing dialog action
  proceedToCheckout = (open) => {
    this.setState({
      dialog: null,
    });

    if (!isLoggedIn()) {
      const { client } = this.props;
      client.writeData({
        data: {
          loginPopUp: {
            __typename: 'LoginPopUp',
            open,
            title: '',
            prompt: '',
            redirectTo: routes.checkout,
          },
        },
      });
      return;
    }

    this.props.router.push({
      pathname: routes.checkout,
    });
  }

  onEmptyBasket = async (cb) => {
    await this.props.resetSession();
    this.onCloseDialog();

    if (cb) {
      cb();
    }
  }

  onConfirmEmptyBasket = (cb) => {
    const dialogType =
      DIALOG_MEMBERSHIP_TYPE.PRODUCT_CONFIRM_EMPTY_BASKET;

    const newDialog =
      getMembershipDialogProps({
        dialogType,
      });

    this.setState({
      dialog: {
        ...newDialog,
        primaryAction: () => this.onEmptyBasket(cb),
        secondaryAction: this.onCloseDialog,
      },
    });
  }

  onProductTypeMismatch = (quote) => (isOverseasProduct, cb) => {
    if (!quote?.basketState) {
      return false;
    }

    const isBasketOverseas = quote.basketState === BASKET_STATES.OVERSEAS;

    if ((isOverseasProduct && !isBasketOverseas) ||
      (!isOverseasProduct && isBasketOverseas)) {
      const dialogType = isBasketOverseas ?
        DIALOG_MEMBERSHIP_TYPE.PRODUCT_TYPE_MISMATCH_OVERSEAS :
        DIALOG_MEMBERSHIP_TYPE.PRODUCT_TYPE_MISMATCH_UK;

      const newDialog =
        getMembershipDialogProps({
          dialogType,
        });
      this.setState({
        dialog: {
          ...newDialog,
          primaryAction: () => this.proceedToCheckout(true),
          secondaryAction: this.onCloseDialog,
          handleFooterAction: () => this.onConfirmEmptyBasket(cb),
        },
      });

      return true;
    }

    return false;
  }

  updateSearchBy(searchBy) {
    this.setState({
      searchBy,
    });
    updateRouterQuery(routes.sites, { ...this.props.router.query, searchBy });
  }

  checkToPushVirtualPageView() {
    // Sites
    let page = virtualPageViewTypes.SITES;

    // Site Results
    if (this.props.router.query.showResults) page = virtualPageViewTypes.SITES_RESULTS;

    // Site Details
    if (this.props.router.query.campsiteId) page = virtualPageViewTypes.SITE_DETAILS;

    // Site Availability
    if (
      this.props.router.query.campsiteId
      && this.props.router.query.siteCode
    ) {
      page = virtualPageViewTypes.SITE_AVAILABILITY;
    }

    if (this.isBasketOpen()) {
      page = virtualPageViewTypes.BASKET;
    }

    dataLayerManager.pushVirtualPageViewEvent(page);
  }

  /**
   * We use a function supplied by SearchWrapper to check its state.
   */
  isBasketOpen() {
    if (this.getBasketOpenState) {
      return this.getBasketOpenState();
    }

    return false;
  }

  render() {
    const {
      sitesConfig,
      router,
      quote,
      portalNode,
    } = this.props;
    const { searchBy } = this.state;

    if (!sitesConfig) {
      return (
        <LoadingContainer>
          <LoadingSpinner />
        </LoadingContainer>
      );
    }

    const isOverseasQuery = router.query?.isOverseas === 'true';
    let defaultTypes = isOverseasQuery ? sitesConfig?.overseasSitesTabDefaultSearchPlaceTypes :
      sitesConfig?.ukSitesTabDefaultSearchPlaceTypes;
    if (!getFeatureFlag(FEATURE_FLAGS.UK_SITES)) {
      defaultTypes = [campsiteTypes.OVERSEAS_SITE];
    }

    const query = parseQueryFromRouter(
      router.query,
      defaultTypes,
    );
    const isTours = query.isTours === 'true' && isOverseasQuery;

    const mapQuery = {
      activePin: query.activePin,
      campsiteId: query.campsiteId,
      campaign: query.campaign,
      end: query.end,
      ne_lat: query.ne_lat,
      ne_lng: query.ne_lng,
      searchLocation: query.location,
      poi_lat: query.poi_lat,
      poi_lng: query.poi_lng,
      siteCode: query.siteCode,
      searchBy,
      start: query.start,
      sw_lat: query.sw_lat,
      sw_lng: query.sw_lng,
      zoomLevel: query.zoomLevel ?? MIN_ZOOM,
      zoomTo: !!query.zoomTo,
      eventType: query.eventType,
      isTours: query.isTours,
      isNearby: query.isNearby,
      userLat: query.userLat,
      userLng: query.userLng,
      userZoom: query.userZoom,
      tourCode: query.tourCode,
      ukItinerary: query.ukItinerary,
    };

    const { bookingId } = router.query;
    const ListingComponent = isTours ? ListingResultTours : ListingResult;
    const allowFeatureFlagMap = getFeatureFlag(FEATURE_FLAGS.MAP);
    const advancedBooking = this.state.additionalCampingPitch && !quote?.quoteId;

    const actualLocationPermission = getActualLocationPermissions(this.state.locationPermission);

    return (

      <AppWrapper>
        <>
          {bookingId &&
            <AmendBar
              id={bookingId}
              onClick={this.props.resetSession}
              router={router}
              quote={quote}
            />
          }
          <SearchWrapper
            defaultQuery={query}
            env={this.props.env}
            onBasketToggle={this.onBasketToggle}
            basketRef={this.onBasketSetRef}
            renderMap={mapProps => (
              <>
                {router.query.clusterControl === 'true' && <ClusteringPanel />}
                {(portalNode && allowFeatureFlagMap) &&
                  <Portals.OutPortal
                    node={portalNode}
                    {...mapQuery}
                    campaign={mapProps.campaign}
                    goToTop={mapProps.goToTop}
                    isMobile={mapProps.isMobile}
                    isOverseas={isOverseasQuery}
                    isVisible
                    onQueInitialMapSearch={mapProps.onQueInitialMapSearch}
                    onQueryChange={mapProps.onQueryChange}
                    onZoomChanged={mapProps.onZoomChanged}
                    isMobileMapOpen={mapProps.isMobileMapOpen}
                    onClickFilterPoi={this.onClickFilterPoi}
                    classInstanceRefFunc={mapProps.classInstanceRefFunc}
                    render={({ map, ...campsiteMapProps }) => (
                      <MapMarkers
                        {...campsiteMapProps}
                        {...{
                          ...mapQuery,
                          // Use renderProps query to update map markers without searching
                          features: mapProps.query.features,
                          types: mapProps.query.types.toString(), // serialize for memo
                        }}
                        selectedEvent={this.state.selectedEvent}
                        poiFilters={this.state.poiFilters}
                        isOverseas={isOverseasQuery}
                        isTours={isTours}
                        tours={this.props.tours}
                      />
                    )}
                    query={mapProps?.query}
                    searchWrapperRef={mapProps.searchWrapperRef}
                    showResults={mapProps.showResults}
                    zoom={mapProps.zoom}
                    tours={this.props.tours}
                    locationPermission={actualLocationPermission}
                    location={this.state.location}
                    requestLocation={this.requestLocation}
                  />
                }
              </>
            )}
          >
            {({
              onSubmitSearchForm,
              showResults,
              onClear,
              handleResultClear,
              ...searchWrapperProps
            }) => (
              <Fragment>
                {(query.campsiteId || query.tourCode) && (
                  <ListingComponent
                    onQueryChange={searchWrapperProps.onQueryChange}
                    onMount={searchWrapperProps.onResultMount}
                    selectedEvent={this.state.selectedEvent}
                    onSelectEvent={this.onSelectEvent}
                    onProductTypeMismatch={this.onProductTypeMismatch(quote)}
                    tours={this.props.tours}
                    toggleMobileMap={searchWrapperProps.toggleMobileMap}
                    toggleBasket={searchWrapperProps.toggleBasket}
                    isMobileMapOpen={searchWrapperProps.isMobileMapOpen}
                    {...query}
                  />
                )}
                <DrawerSearch
                  searchTabsClick={() => {
                    handleResultClear();
                    onClear(null, query);
                  }}
                  toggle={searchWrapperProps.toggle}
                  toggleBasket={searchWrapperProps.toggleBasket}
                  isBasketVisible={searchWrapperProps.isBasketVisible}
                  stickyAdvert={!showResults}
                  onProductTypeMismatch={this.onProductTypeMismatch(quote)}
                  show={!query.campsiteId && !query.tourCode}
                >
                  {drawerSearchProps => (
                    <CampsiteSearch
                      {...searchWrapperProps}
                      onClear={onClear}
                      onSubmitSearchForm={onSubmitSearchForm}
                      searchBy={searchBy}
                      updateSearchBy={this.updateSearchBy}
                      showResults={!!showResults}
                      formRef={drawerSearchProps.formRef}
                      types={searchWrapperProps.query.types}
                      onProductTypeMismatch={this.onProductTypeMismatch(quote)}
                      defaultTypes={defaultTypes}
                      quote={quote}
                      tours={this.props.tours}
                      locationPermission={this.state.locationPermission}
                      location={this.state.location}
                      requestLocation={this.requestLocation}
                      mapMovedCount={searchWrapperProps?.mapMovedCount}
                    />
                  )}
                </DrawerSearch>

                {!!query.siteCode && (
                  <DrawerCampsiteAvailability
                    useMarginForAnimation
                    direction="left"
                    inView={!!query.siteCode}
                    open={!!query.siteCode}
                    forwardRef={this.searchListing}
                    onRequestChange={() => updateRouterQuery(
                      routes.sites, { ...query, siteCode: null },
                    )}
                    toggleBasket={searchWrapperProps.toggleBasket}
                    additionalCampingPitch={advancedBooking}
                    onToggleAdditionalCampingPitch={advancedBooking ?
                      this.onAdditionalCampingPitchBack : undefined}
                  >
                    <Quote isOverseas={isOverseasQuery}>
                      {quoteProps => (
                        <QuoteCampsite
                          onQueryChange={searchWrapperProps.onQueryChange}
                          onResultChange={searchWrapperProps.onResultMount}
                          onSelectEvent={this.onSelectEvent}
                          query={searchWrapperProps.availabilityQuery}
                          result={searchWrapperProps.result}
                          toggleBasket={searchWrapperProps.toggleBasket}
                          selectedEvent={this.state.selectedEvent}
                          addingCampingGuests={this.state.addingCampingGuests}
                          additionalCampingPitch={this.state.additionalCampingPitch}
                          onToggleAddingCampingGuests={this.onToggleAddingCampingGuests}
                          onToggleAdditionalCampingPitch={this.onToggleAdditionalCampingPitch}
                          onAdvancedGuestEdit={this.onAdvancedGuestEdit}
                          onProductTypeMismatch={this.onProductTypeMismatch}
                          {...quoteProps}
                        />
                      )}
                    </Quote>
                  </DrawerCampsiteAvailability>
                )}
                {!!query.tourCode && (
                  <DrawerTours
                    useMarginForAnimation
                    direction="left"
                    inView={!!query.tourCode}
                    open={!!query.tourCode}
                    forwardRef={this.searchListing}
                    onRequestChange={() => updateRouterQuery(
                      routes.sites, { ...query, tourCode: null },
                    )}
                    toggleBasket={searchWrapperProps.toggleBasket}
                  >
                    <TourItinerary
                      tour={
                        this.props.tours.find(
                          (tourItem) => tourItem.id?.toString() === query.tourCode,
                        )}
                    />
                  </DrawerTours>
                )}

                {query.reviews &&
                  <ReviewsDrawer
                    useMarginForAnimation
                    direction="left"
                    inView={!!(query.reviews && query.campsiteId)}
                    open={!!(query.reviews && query.campsiteId)}
                    onRequestChange={() => updateRouterQuery('/sites', { ...query, reviews: null })}
                  >
                    <SiteCard
                      handleCheckoutToggle={searchWrapperProps.toggleBasket}
                    >
                      <Reviews
                        campsiteId={query.campsiteId}
                        visible={!!(query.reviews && query.campsiteId)}
                        scrollWrapper={this.reviewWrapper}
                      />
                    </SiteCard>
                  </ReviewsDrawer>
                }
              </Fragment>
            )}
          </SearchWrapper>
          <MembershipDialog
            {...this.state.dialog}
            open={!!this.state.dialog}
          />
          <Filters
            onClose={this.onClickFilterPoi}
            onSubmit={this.onFilterPoiSubmit}
            query={{ ...query, features: this.state.poiFilters }}
            active={this.state.poiFiltersActive}
            isPoi
          />
        </>
        <DisplayToRootContainer />
      </AppWrapper>
    );
  }
}

export default compose(
  withData,
  withRouter,
  withApollo,
  withAppData,
  withSitesConfig,
  withReversePortal(CampsiteMap, {
    props: () => ({
      isVisible: false,
    }),
    skip: ({ router }) => router.query?.bookingWidget === 'true',
  }),
  graphql(GET_OVERSEAS_TOURS, {
    skip: ({ router }) => router.query?.bookingWidget === 'true',
    options: getToursOptions,
    props: ({ data }) => ({
      tours: data.Tours?.tours ?? [],
    }),
  }),
  graphql(GET_QUOTE, {
    options: {
      fetchPolicy: FetchPolicy.CACHE_FIRST,
    },
    skip: ({ router }) => router.query?.bookingWidget === 'true',
    props: ({ data }) => ({
      quote: data?.quote,
    }),
  }),
  graphql(RESET_SESSION, {
    props: ({ mutate }) => ({
      resetSession: () => mutate({
        refetchQueries: ['Quote'],
      }),
    }),
  }),
)(Sites);
